<div id="app" *ngIf="appService.accountLoaded && appService.langLoaded && checkIsBrowser()">
    <div class="app-content" [ngClass]="{'grayBg' : isBasketOrCheckout()}">
        <app-header></app-header>
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

</div>

<app-header-popup *ngIf="appService.popuped && appService.accountLoaded && appService.langLoaded && checkIsBrowser()"></app-header-popup>

<!-- <app-cookies-warning></app-cookies-warning> -->

<div class="loaderContainer" *ngIf="!appService.accountLoaded || !appService.langLoaded || !checkIsBrowser()">
    <div class="logoLoader"></div>
    <div class="loader"></div>
</div>

<div *ngIf="!checkIsBrowser() && appService.langLoaded">
    <router-outlet></router-outlet>
</div>
