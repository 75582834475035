import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';



@Injectable({providedIn: 'root'})
export class ContactService {
    constructor(
        private httpClient: HTTPClientService
    ) { }

    postContactForm(form): Promise<any> {
        return this.httpClient.post('contact/send', form)
        .then((response: any) => { return response })
        .catch((response: any) => { return response })
    }

    sendInterest(accountId, mail,productId){
        return this.httpClient.post('contact/interest', {accountId:accountId, mail:mail,productId:productId})
        .then((response: any) => { return response })
        .catch((response: any) => { return response })
    }

    sendInterestAnonymous(mail,productId){
        return this.httpClient.post('contact/interestAnonymous', {mail:mail,productId:productId})
        .then((response: any) => { return response })
        .catch((response: any) => { return response })
    }

    getCountriesInformations(){
        return this.httpClient.get('contact/offices')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getCustomers(){
        return this.httpClient.get('companys/allcustomers')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getTeams(){
        return this.httpClient.get('our-team')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getMapDots(){
        return this.httpClient.get('contact/map-dots')
        .then((response: any) => { return response })
        .catch(console.error);
    }

}
