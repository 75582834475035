import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({providedIn: 'root'})
export class ResellerService {

    constructor(
      private httpClient: HTTPClientService
    ) { }

    refreshResellers(countryId){
        return this.httpClient.get('resellers/'+countryId)
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getMyClients(){
      return this.httpClient.get('reseller/clients')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getMyClientsOrders(){
        return this.httpClient.get('reseller/clients/orders')
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getResellerInfos(resellerId){
        return this.httpClient.get('reseller/infos/'+resellerId)
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getAllResellers(){
        return this.httpClient.get('reseller/map')
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getCountryByIp(){
        return this.httpClient.get('geoloc')
          .then((response: any) => { return response })
          .catch(console.error);
    }

    contactReseller(contactingReseller){
        return this.httpClient.post('reseller/contact', {infos : contactingReseller})
          .then((response: any) => { return response })
          .catch(console.error);
    }
}
