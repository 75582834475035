import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import{ TheyTrusUsService } from '../../services/theyTrusUs.service'

@Component({
    selector: 'app-they-trust-us',
    templateUrl: './they-trust-us.component.html',
    styleUrls: ['./they-trust-us.component.css']
})
export class TheyTrustUsComponent implements OnInit {

    @Input()title:any = this.translate.instant("Ils nous font confiance");

    public images = []

    constructor(
        public theyTrusUsService: TheyTrusUsService,
        public translate : TranslateService
        ) { }

    ngOnInit() {
        this.theyTrusUsService.getTrustCompanies(1)
        .then((companies)=>{
            for(let company of companies){
              if(company.name){
                this.images.push({url: this.theyTrusUsService.getLogoUrl(company.logo_url), name: company.name});
              }
              else{
                this.images.push({url: this.theyTrusUsService.getLogoUrl(company.logo_url), name: 'company'});
              }
            }
            this.images.sort(function() { return 0.5 - Math.random() });
        })

    }

}
