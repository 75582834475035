import { Component, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { NewsService } from '../../services/news.service';
import { LanguageService } from '../../services/language.service';
import {isBrowser} from '../../thirdparty/isBrowser';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-featured-news',
    templateUrl: './featured-news.component.html',
    styleUrls: ['./featured-news.component.css']
})
export class FeaturedNewsComponent {

    public posts: any = [];
    public isRd = false;
    public display = false;
    public ecart = 300;
    @ViewChild('content') public content: ElementRef;

    constructor(
        public newsService: NewsService,
        public language : LanguageService,
        @Inject(DOCUMENT) public document: Document
    ) {}

    refreshActus() {
        this.newsService.getNewss(0, 3)
        .then(res => {
            if(res['results']){
                this.posts = res['results']
            }
            else{
                this.posts = []
            }
            for(let post of this.posts){
                if(!post['link']) {
                    if(this.isRd){
                        post['link'] = '/research-innovation/'+this.newsService.getPostLink(post)
                    }
                    else{
                        post['link'] = '/blog/'+this.newsService.getPostLink(post)
                    }

                }
                if(!post['img']) { post['img'] = this.newsService.getNewsHeader(post['imageHeader']) }
                if(!post['shortDesc']){
                    if(post['html']){
                        this.newsService.getNewsHTML(post['html'])
                        .then((res) =>{
                            if(res){
                                post['shortDesc'] = res.replace(new RegExp("<.[^<>]*>", "gi" )," ");
                            }
                            else{
                                post['shortDesc'] = '...'
                            }
                        })
                    }
                    else{
                        post['shortDesc'] = '...'
                    }
                }
            }
        })
    }


    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event) {
      if(isBrowser()){
        const scroll = this.document.documentElement.scrollTop;
        const offsetTop = this.content.nativeElement.offsetTop;
        const screenHeight = window.innerHeight;
        if(offsetTop-scroll-screenHeight < this.ecart && this.display == false){
          this.display = true;
          this.refreshActus();
        }
      }
    }

}
