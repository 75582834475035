<div style="" class="login-container p-4">
  <!-- <h3 class="mb-4 text-center">{{"Se connecter" | translate}}</h3> -->
  <form (ngSubmit)="login()">
      <div class="form-group">
          <label for="email">{{'Adresse email' | translate}}:</label>
          <input type="email" class="form-control" name="email" id="email" [(ngModel)]="user.mail">
      </div>
      <div class="form-group">
          <label for="password">{{'Mot de passe' | translate}}:</label>
          <div class="input-group mb-3">
              <input type="{{(showPassword) ? 'text' : 'password'}}" class="form-control password" name="password" id="password" [(ngModel)]="user.password">
              <div class="input-group-append text-white">
                  <span class="input-group-text cursor-pointer" (click)="showPassword = !showPassword">
                      <i class="far fa-eye" *ngIf="!showPassword"></i>
                      <i class="far fa-eye-slash" *ngIf="showPassword"></i>
                  </span>
              </div>
          </div>
          <small (click)="newPasswd()" class="text-immersive cursor-pointer">{{"M'envoyer un nouveau mot de passe" | translate}}</small>
      </div>

      <div class="form-group">
          <button type="submit" class="btn btn-block btn-white">{{"Connexion" | translate}}</button>
      </div>
  </form>
  <button (click)="register()" class="btn btn-block btn-action">{{"Créer mon compte" | translate}}</button>

</div>
