import { Injectable, EventEmitter } from '@angular/core';
import {Observable,  Subject } from 'rxjs';
import { Router } from '@angular/router';

import { HTTPClientService } from './httpclient.service';
import { LocalstorageService } from './localstorage.service'
import {UtilsService} from './utils.service'
import { LanguageService } from './language.service';

import { environment } from '../../../environments/environment';
import {isBrowser} from '../thirdparty/isBrowser'


@Injectable({providedIn: 'root'})
export class AccountService {

    private token: string;

    public userInfos:any = {}

    public tokenUpdated:EventEmitter<any> = new EventEmitter();

    public accountLoaded: EventEmitter<any> = new EventEmitter();

    public boolToggle = false;

    public catchRedirection = false
    public catchRefresh = false

    constructor(
        private httpClient: HTTPClientService,
        private utilsService:UtilsService,
        public router : Router,
        public localStorage : LocalstorageService,
        public language : LanguageService
    ) {
    }

    login(mail, password): Promise<any> {
        return this.httpClient.post('login', {mail: mail, password: password})
        .then((response: any) => {
            var res = response;
            if(res && res['err']){
                console.error(res.err)
                this.token=null;
                this.httpClient.updateToken("");
                return res;

            }else{

                this.httpClient.updateToken(res.token);
                this.token = res.token;
                this.tokenUpdated.emit(this.token);
                this.loadAccountInfos()
                return res;
            }
        })
        .catch((err)=>{
            console.error(err)
            this.token=null;
            this.httpClient.updateToken("");

        });
    }

    logout() {
        if(isBrowser() && window && window['$chatwoot']){
            window['$chatwoot'].reset();
        }
        this.userInfos = {}
        this.token = null;
        this.httpClient.updateToken("");
        this.tokenUpdated.emit(this.token);
        this.router.navigate(this.language.navigateAccordingToLang(['/']))
        location.reload();

    }

    isLogged(): boolean {
        this.token = this.localStorage ? this.localStorage.getItem('AuthToken') : null;

        return (this.token !== null && this.token !== "" && this.token !== undefined && this.userInfos['id']);
    }

    isToggled(check){
        this.boolToggle = check;
        return this.boolToggle;
    }

    showToggled(){
        return this.boolToggle;
    }

    checkLogin(){
        return new Promise((resolve)=>{
            var token = null
            if(this.localStorage){
                token = this.localStorage.getItem('AuthToken')
            }
            if(token){
                this.getAccountInfo()
                .then((res)=>{
                    if(res['id']){
                        resolve(true)
                    }
                    else{
                        resolve(false)
                    }
                })
            }
            else{
                resolve(false)
            }
        })
    }

    newPasswd(mail) {
        return this.httpClient.post('account/reset-password', {mail:mail})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    changePasswd(oldPassword, newPassword) {
        return this.httpClient.post('account/changepasswd', {oldpwd: oldPassword, newpwd: newPassword}, true)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    register(user) {
        return this.utilsService.getDeviceId()
        .then((deviceId)=>{
            user.deviceId = deviceId;
            return this.httpClient.put('register', user)
        })
        .then((response: any) => { return response })
    }

    getAccountProducts(offset=0, limit=12): Promise<any> {
        return this.httpClient.get('account/products/'+offset+'/'+limit)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getAccountInfo(): Promise<any> {
        return this.httpClient.get('account')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getAccountAvatar(name): string {
        return environment.dataStorage + "avatars/" + name;
        /*
            return this.httpClient.get('avatars/' + name)
            .then((response: any) => response)
            .catch(console.error);
        */
    }

    editAccountInfo(profil): Promise<any> {
        return this.httpClient.post('account', profil)
        .then((response: any) => {this.loadAccountInfos()})
        .catch(console.error);
    }

    buyOffer(offer, comment=''): Promise<any> {
        return this.httpClient.post('account/buy', {offer: offer, comment: comment})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getKeys(): Promise<any> {
        return this.httpClient.get('account/myproducts')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    sendTmpAccount(accountObj):Promise<any> {
        return this.httpClient.put('tmp/accounts',accountObj)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getTmpAccountByToken(token):Promise<any> {
        return this.httpClient.get('tmp/accounttoken/'+token)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getUserInfoAsync(){
        if(this.userInfos && this.userInfos["id"]){
            return Promise.resolve(this.userInfos)
        }else{
            return this.loadAccountInfos();
        }
    }

    loadAccountInfos(){
        if(isBrowser()){
            return this.httpClient.get('account')
            .then((response: any) => {
                this.userInfos = response
                this.userInfos['avatar'] = this.getAccountAvatar(this.userInfos['pic']+'?unique=' + new Date().getTime())
                this.accountLoaded.emit("loaded");
                this.setChatwootInfos()
                return Promise.resolve(this.userInfos);
            })
            .catch((err)=>{
                this.accountLoaded.emit("loaded");
                // console.error(err)
            });
        }
        else{
            this.userInfos = {}
            this.setChatwootInfos()
            this.accountLoaded.emit("loaded");
            return Promise.resolve({})
        }

    }

    getMailSubs(){
        return this.httpClient.get('accounts/mailSub')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    setMailSubs(subs){
        return this.httpClient.post('accounts/mailSub', {"sub":subs})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    postTicket(datas){
        return this.httpClient.post('account/ticket', datas)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getTickets(){
        return this.httpClient.get('account/support/messages')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getTicket(id){
        return this.httpClient.get('account/support/message/'+id)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    editTicket(id,content){
        return this.httpClient.post('account/support/message/'+id,{content:content})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getInfoByPostalCodeAndCountry(postalCode,country){

        var url = 'https://www.zippopotam.us/'+country+"/"+postalCode;
        return url;
    }

    getAccounts(): Promise<any> {
        return this.httpClient.get('accounts')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    activateAccount(content){
        return this.httpClient.post('account/activate/',{content:content})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getRenewalNeeded(){
        return this.httpClient.get('account/needdedrenewal')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getAccountInfosForBilling(){
        return this.httpClient.get('account/billing-infos')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getAccountByIds(ids){
        return this.httpClient.get('hub/account/ids/'+ids.join(','))
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getAccountXp(){
        return this.httpClient.get('hub/account/scoring/advanced')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    checkResetPasswordToken(token){
        return this.httpClient.get('account/reset-password/check/'+encodeURIComponent(token))
        .then((response: any) => { return response })
        .catch(console.error);
    }

    changePasswordWithToken(token, newPassword){
        return this.httpClient.post('account/reset-password/change', {password : newPassword, token : encodeURIComponent(token)})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    setChatwootInfos(){
        if(isBrowser() && window && window['$chatwoot'] && this.userInfos && this.userInfos['id']){
            window['$chatwoot'].setUser(this.userInfos['id'], {
                email: this.userInfos['mail'],
                name: this.userInfos['name'],
                avatar_url: this.userInfos['avatar'],
            });
            window['$chatwoot'].setCustomAttributes({
                accountId: this.userInfos['id'],
                company : this.userInfos['company']
            });
        }
    }

}
