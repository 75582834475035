import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({providedIn: 'root'})
export class ReportService {

  constructor(
      private httpClient: HTTPClientService
  ) { }

  getReports(productID): Promise<any> {
    return this.httpClient.get('reports/'+productID)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getReportsFromAccountAndProduct(productId,accountId) : Promise<any> {
    return this.httpClient.get('statreports/'+accountId+"/"+productId)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getReportsFromAccount(): Promise<any> {
    return this.httpClient.get('statreports/lastreports')
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getAvailableTokens(productID): Promise<any> {
    return this.httpClient.get('reports/unused/'+productID)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getInUseTokens(productID): Promise<any> {
    return this.httpClient.get('reports/used/'+productID)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  generateToken(productID, uses, account_id?): Promise<any> {
    return this.httpClient.post('reports', {product_id: productID, uses: uses, account_id: account_id})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  editToken(token): Promise<any> {
    return this.httpClient.post('report/' + token.id, token)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getUserPrefs(productId,accountId) : Promise<any>{
    return this.httpClient.get('report/pref/' + accountId+"/"+productId)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getRank(productId,accountId) : Promise<any>{
    return this.httpClient.get('report/rank/' + accountId+"/"+productId)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  updatePref(prefName,accountId,productId,level,rank=null, userName=null) : Promise<any>{
    return this.httpClient.post('report/pref',{prefName:prefName,accountId:accountId,productId:productId,level:level,rank:rank,userName:userName})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  changeDefaultKey(authId,productId){
    return this.httpClient.post("setAuth/default",{productId:productId,authId:authId})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getProductsWithReportsByAccount(accountId, sortType="asc", type="all", category="all", search=""){
      return this.httpClient.get("reports/account/products/"+accountId+"/"+sortType+'/'+type+'/'+category+'/'+search)
        .then((response: any) => { return response })
        .catch(console.error);
  }

  getSessionsWithFilters(productId, accountId, sortType, sortBy, periodStart, periodEnd, periodAll, scoreCustom, scoreType, scoreValue){
      return this.httpClient.get("reports/account/sessions/"+productId+'/'+accountId+'/'+sortType+'/'+sortBy+'/'+periodStart+'/'+periodEnd+'/'+periodAll+'/'+scoreCustom+'/'+scoreType+'/'+scoreValue)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getProductsWithReportsForAverage(groupType, sortType="asc", type="all", category="all", group='all', search=""){
      return this.httpClient.get("reports/average/products/"+groupType+"/"+sortType+'/'+type+'/'+category+'/'+group+'/'+search)
        .then((response: any) => { return response })
        .catch(console.error);
  }

  getSessionsWithFiltersForAverage(productId, groupType, sortType, sortBy, periodStart, periodEnd, periodAll, scoreCustom, scoreType, scoreValue, group){
      return this.httpClient.get("reports/average/sessions/"+productId+'/'+groupType+'/'+sortType+'/'+sortBy+'/'+periodStart+'/'+periodEnd+'/'+periodAll+'/'+scoreCustom+'/'+scoreType+'/'+scoreValue+'/'+group)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getDatasForCalendar(periodSelected, productId, reportType){
      return this.httpClient.get("reports/average/calendar/"+periodSelected+"/"+productId+"/"+reportType)
      .then((response: any) => { return response })
      .catch(console.error);
  }

}
