<!-- carousel -->
  <div class="container my-2 people-carousel py-3 px-3">
    <div class="row text-center pt-2">
      <div class="col-md-10 photo" *ngIf="slides">
      <ng-container *ngFor="let slide of slides; let i = index">
        <img
          *ngIf="i === currentSlide && slide.src && slide.name"
          [src]="slide.src"
          alt="{{slide.name}} picture"
          [title]="slide.name"
          class="slide rounded-circle fade photo-people"
          loading="lazy"
        />
        <img
          *ngIf="i === currentSlide && slide.src && !slide.name"
          [src]="slide.src"
          alt="picture of a person"
          title="picture"
          class="slide rounded-circle fade photo-people"
          loading="lazy"
        />
        <img
          *ngIf="i === currentSlide && slide.logo && slide.logoName"
          [src]="slide.logo"
          alt="{{slide.logoName}} logo"
          [title]="slide.logoName"
          class="fade logo mt-3"
          loading="lazy"
        />
        <img
          *ngIf="i === currentSlide && slide.logo && !slide.logoName"
          [src]="slide.logo"
          alt="logo of a company"
          title="logo"
          class="fade logo mt-3"
          loading="lazy"
        />
      </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 infos-people pt-3" *ngIf="slides">
        <ng-container *ngFor="let slide of slides; let i = index">
          <p *ngIf="i === currentSlide" class="p-0 m-0 bold fade">{{slide.name}}</p>
        </ng-container>
        <ng-container *ngFor="let slide of slides; let i = index">
          <p *ngIf="i === currentSlide" class="fade">{{slide.job}}</p>
        </ng-container>
      </div>
    </div>
    <div class="row text-center" *ngIf="slides?.length > 1">
      <div *ngIf="slides" class="dots">
       <input type="radio" name="" title="" class="dot radio" *ngFor="let slide of slides; let i = index" (click)="currentSlide = i" [checked]="(currentSlide === i)"/>
       </div>
    </div>
    <!-- Carousel rows --> <a class="carousel-control left carousel-control-prev" (click)="onPreviousClick()" *ngIf="slides && slides?.length > 1"> <i class="fa fa-angle-left"></i> </a> <a class="carousel-control right carousel-control-next" (click)="onNextClick()" *ngIf="slides && slides?.length > 1"> <i class="fa fa-angle-right"></i> </a>
</div>
