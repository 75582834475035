import { Injectable,EventEmitter } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

import { environment } from '../../../environments/environment'

@Injectable({providedIn: 'root'})
export class ClientOrderService {

  public basketCalculateEvent:EventEmitter<any> = new EventEmitter<any>();


  constructor(
      private httpClient: HTTPClientService
  ) { }

    getClientOrder(clientOrderId){
        return this.httpClient.get('clientorder/'+clientOrderId)
          .then((response: any) => { return response })
          .catch(console.error);
    }

    calculateBasket(clientOrder,updatePopup=true){
        return this.httpClient.post('clientorder/calculate', {clientOrder: clientOrder})
          .then((response: any) =>{
            if(updatePopup){
              this.basketCalculateEvent.emit(response);
            }
            return response
          })
          .catch(console.error);
    }

    getMyOrders(){
        return this.httpClient.get('clientorders')
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getPreviewPDF(clientOrder){
        return this.httpClient.post('clientorder/previewpdf', {clientOrder:clientOrder})
          .then((response: any) => { return response })
          .catch(console.error);
    }

    registerOrder(clientOrder){
        return this.httpClient.post('clientorder/create', {clientOrder:clientOrder})
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getPdfUrl(filename){
        return environment.dataStorage + "orderpdf/" + filename;
    }

    askQuotation(clientOrder, captcha){
        return this.httpClient.post('clientorder/askquotation',{clientOrder:clientOrder,captcha:captcha})
          .then((response: any) => { return response })
          .catch(console.error);
    }

}
