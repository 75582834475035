<div class="rules-title mb-1">{{"Votre mot de passe doit contenir au moins :" | translate}}</div>
<ul class="pl-2">
	<li *ngFor="let rule of rules" [ngClass]="{'text-danger' : !rule['valid'], 'text-success': rule['valid']}">
		<span class="pr-2" title="{{((rule['infos']) ? rule['infos'] : '')}}" data-toggle="tooltip" data-placement="right">
			<i class="far fa-fw mr-1" [ngClass]="{'fa-times-circle' : !rule['valid'], 'fa-check-circle': rule['valid']}"></i>

			<span>{{rule['name']}}</span>
			<sup *ngIf="rule['infos']"><i class="fas fa-info ml-1"></i></sup>
		</span>


	</li>
</ul>
