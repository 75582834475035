import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BasketService } from "../../services/basket.service";
import { LanguageService } from "../../services/language.service";
import { isBrowser } from "../../thirdparty/isBrowser";


declare var Swal:any;
declare var $:any;

@Component({
	selector: "lang-flag",
	templateUrl: "./lang-flag.component.html",
	styleUrls: ["./lang-flag.component.scss"],
})
export class LangFlagComponent implements OnInit {
	constructor(
		public translate: TranslateService,
		private basketService: BasketService,
        private language : LanguageService
	) {}

	ngOnInit(): void {}

    swalLangChange(){
        Swal.fire({
            cancelButtonText: this.translate.instant('Annuler'),
            showCancelButton: true,
            html : `
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="customLangSelector flagFr">
                        <input type="radio" id="langFR" name="langSelector" value="fr" `+(this.translate['store']['currentLang'] == "fr" ? "checked" : "")+`>
                        <label for="langFR">Français</label>
                    </div>
                    <div class="customLangSelector flagEn">
                        <input type="radio" id="langEN" name="langSelector" value="en" `+(this.translate['store']['currentLang'] == "en" ? "checked" : "")+`>
                        <label for="langEN">English</label>
                    </div>
                </div>
                <div class="col-12 d-sm-none">
                    <hr>
                </div>
                <div class="col-12 col-sm-6">`+
                    Object.entries(this.basketService.conversionArray).map(devise => `
                        <div class="customDeviseSelector">
                            <input type="radio" id="devise`+devise[0]+`" name="deviseSelector" value="`+devise[0]+`" `+(this.basketService['currentCurrency'] == devise[0] ? "checked" : "")+`>
                            <label for="devise`+devise[0]+`" class="d-flex align-items-center justify-content-between">
                                <div>`+devise[0]+`</div>
                                <div><i class="fas fa-fw fa-`+devise[1].icon+`"></i></div>
                            </label>
                        </div>
                    `).join('')
              +`</div>
            </div>
            `,
            confirmButtonColor: "#5dbfd4",
            preConfirm: ()=>{
                return new Promise((resolve) =>{
                    if(isBrowser()) resolve({
                        "deviseSelected" : $('input[name=deviseSelector]:checked').val(),
                        "langSelected" : $('input[name=langSelector]:checked').val()
                    })
                })
            }
        })
        .then((res)=>{
            if(res.value){
                if(res.value['deviseSelected'] && this.basketService['currentCurrency'] != res.value['deviseSelected']){
                    this.basketService.changeCurrency(res.value['deviseSelected'])
                }
                if(res.value['langSelected'] && this.translate['store']['currentLang'] != res.value['langSelected']){
                    this.language.changeLanguage(res.value['langSelected'])
                }
            }
        })
    }
}
