import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'flag',
    templateUrl: './flag.component.html',
    styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {

    @Input() country = null

    constructor() { }

    ngOnInit(): void {
    }

}
