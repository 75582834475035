<header (click)="appService.hidePopup()">
    <nav class="fixed-top px-3">
        <div class="left d-flex align-items-center" [ngClass]="{'showed' : showLeft}">
            <img src="assets/logo_flat_textenoir_45.png" height="45px" alt="Logo of Immersive Factory" title="Immersive Factory" class="mr-4 cursor-pointer" (click)="goHomeOrScroll()" loading="lazy">

            <div class="p-0 m-0">
                <ul class="menu list-unstyled">
                    <li *ngFor="let menu of whiteMenu" [ngClass]="{'dropdown': menu.isDropDownMenu}" class="py-xl-2">
                        <a *ngIf="!menu.isDropDownMenu" (click)="showLeft=false" [routerLink]="language.navigateAccordingToLang([menu.link])" [ngClass]="{'blue-underline':checkActiveLink(menu), 'font-weight-bold':menu.isBold}" class="mr-custom cursor-pointer" title="{{menu.value}}"><span>{{menu.value}}<img *ngIf="menu.logo" class="img-logo ml-1" src="{{menu.logo}}" alt="{{menu.alt}}" title="{{menu.title}}" loading="lazy"></span></a>

                        <div [ngClass]="{'blue-underline': checkActiveSubmenuLink(menu.subMenuList), 'font-weight-bold':menu.isBold}" *ngIf="menu.isDropDownMenu" class="mr-custom cursor-pointer link-dropdown" id="navbarDropdownMenuLink" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false"><span>{{menu.value}}<img *ngIf="menu.logo" class="img-logo ml-1" src="{{menu.logo}}" alt="{{menu.alt}}" title="{{menu.title}}" loading="lazy"></span></div>
                        <ul *ngIf="menu.isDropDownMenu" class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                          <ng-container *ngFor="let subMenu of menu.subMenuList" class="p-0 m-0">
                              <li class="py-1 cursor-pointer test" *ngIf="!subMenu.tags && !subMenu.param">
                                <ul *ngIf="subMenu.submenu" class="submenuAside">
                                  <ng-container *ngFor="let subSubMenu of subMenu.submenu" class="p-0 m-0">
                                    <li class="py-1 cursor-pointer" *ngIf="!subSubMenu.tags && !subSubMenu.param" (click)="showLeft=false" [routerLink]="language.navigateAccordingToLang([subSubMenu.link])" title="{{subSubMenu.value}}">
                                        <a [ngClass]="{'blue-underline':checkActiveLink(subSubMenu)}"><span>{{ subSubMenu.value }}</span></a>
                                    </li>
                                  </ng-container>
                                </ul>
                                  <a *ngIf="subMenu.submenu" [ngClass]="{'blue-underline':checkActiveLink(subMenu)}"><span>{{ subMenu.value }}</span></a>
                                  <a *ngIf="!subMenu.submenu" (click)="showLeft=false" [routerLink]="language.navigateAccordingToLang([subMenu.link])" [ngClass]="{'blue-underline':checkActiveLink(subMenu)}" title="{{subMenu.value}}"><span>{{ subMenu.value }}</span></a>
                              </li>
                              <li class="py-1 cursor-pointer test" *ngIf="subMenu.tags && subMenu.param">
                                  <ul *ngIf="subMenu.submenu" class="submenuAside">
                                    <ng-container *ngFor="let subSubMenu of subMenu.submenu" class="p-0 m-0">
                                      <li class="py-1 cursor-pointer" *ngIf="!subSubMenu.tags && !subSubMenu.param" (click)="showLeft=false" [routerLink]="language.navigateAccordingToLang([subSubMenu.link])" title="{{subSubMenu.value}}">
                                          <a [ngClass]="{'blue-underline':checkActiveLink(subSubMenu)}"><span>{{ subSubMenu.value }}</span></a>
                                      </li>
                                    </ng-container>
                                  </ul>
                                  <a *ngIf="subMenu.submenu" [ngClass]="{'blue-underline':checkActiveLink(subMenu)}"><span>{{ subMenu.value }}</span></a>
                                  <a *ngIf="!subMenu.submenu" (click)="showLeft=false" [routerLink]="language.navigateAccordingToLang([subMenu.link])" [queryParams]="{tags: subMenu.tags}" fragment="{{subMenu.param}}" [ngClass]="{'blue-underline':checkActiveLink(subMenu)}" title="{{subMenu.value}}"><span>{{ subMenu.value }}</span></a>
                              </li>
                          </ng-container>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="loca d-block d-sm-none">
                <div class="btn btn-dark cursor-pointer" (click)="swalLangChange()">
                    <img src="assets/{{translate.store.currentLang}}_flag.png" alt="Icon of the {{translate.store.currentLang}} flag" title="{{translate.store.currentLang}}" style="color:transparent;" loading="lazy">
                    <span *ngIf="basketService.currentCurrency" class="mx-2 separator"></span>
                    <i *ngIf="basketService.currentCurrency" class="fa fa-{{basketService.currentCurrency.toLowerCase()}}" aria-hidden="true"></i>
                </div>
            </div>

            <div class="loca2 d-block d-lg-none p-4">

                <a *ngIf="!deleteButtons" class="btn cursor-pointer btn-info px-3 mb-3 rounded-full" (click)="showLeft=false;" [routerLink]="language.navigateAccordingToLang(['/subscription'])" title="subscribe">
                    <app-svg-icon [src]="'assets/svg/sign-in-alt-solid.svg'" [size]="'18px'"></app-svg-icon>
                    <span class="mx-1"></span>
                    <span>{{"Commander" | translate}}</span>
                </a>
                <a class="btn cursor-pointer btn-info px-3 mb-3 rounded-full" (click)="showLeft=false;" [routerLink]="language.navigateAccordingToLang(['/about-us/o'])" title="contact">
                    <i class="fas fa-envelope"></i>
                    <span class="mx-1"></span>
                    <span>{{"Contact" | translate}}</span>
                </a>
            </div>

            <div class="social d-xl-none p-4">
                <a class="twitter" target="_blank" href="https://twitter.com/i/redirect?url=https%3A%2F%2Ftwitter.com%2Fimmersivefactor%3Fcn%3DYWRkcmVzc19ib29rX2NvbnRhY3RfYWRkZWQ%253D%26refsrc%3Demail&amp;t=1&amp;cn=YWRkcmVzc19ib29rX2NvbnRhY3RfYWRkZWQ%3D&amp;sig=b3720891e99093100f692c33437d35c222c28d94&amp;iid=28b87680a2ab4f3d86ee060288204c12&amp;uid=121430916&amp;nid=60+1110" title="twitter">
                    <img src="/assets/twitter.svg" alt="Logo of the Twitter company" title="Twitter" loading="lazy">
                </a>
                <a class="linkedin" target="_blank" href="https://www.linkedin.com/company/immersive-factory" title="Linkedin">
                    <img src="/assets/linkedin.svg" alt="Logo of the Linkedin company" title="Linkedin" loading="lazy">
                </a>
                <a class="youtube" target="_blank" href="https://www.youtube.com/channel/UCSVDmC2pRJoDNy_-obuf7ZA/featured" title="Youtube">
                    <img src="/assets/youtube.svg" alt="Logo of the YouTube company" title="YouTube" loading="lazy">
                </a>
            </div>
        </div>


        <div class="left-mobile d-block d-custom-none" (click)="actionToggle()">
            <app-svg-icon class="cursor-pointer" [src]="'assets/svg/bars-light.svg'" [size]="'25px'"></app-svg-icon>
        </div>
        <div class="logo-mobile d-flex d-lg-none" (click)="showLeft=false;goHomeOrScroll()">
            <img src="assets/logo_if.png" alt="Logo of the Immersive Factory company" title="Immersive Factory" loading="lazy">
        </div>
        <div class="right d-flex align-items-center">

            <div class="loca2 d-none d-lg-flex mr-3" (click)="showLeft=false" style="gap:10px">

                <a *ngIf="!deleteButtons" class="btn cursor-pointer btn-info rounded-full" [routerLink]="language.navigateAccordingToLang(['/subscription'])" title="subscribe">
                    <app-svg-icon [src]="'assets/svg/sign-in-alt-solid.svg'" [size]="'18px'"></app-svg-icon>
                    <span class="mx-1"></span>
                    <span>{{"Commander_" | translate}}</span>
                </a>
                <a class="btn cursor-pointer btn-info rounded-full" *ngIf="pageIsNotAboutUs" [routerLink]="language.navigateAccordingToLang(['/about-us/o'])" title="contact">
                    <i class="fas fa-envelope"></i>
                    <span class="mx-1"></span>
                    <span>{{"Contact" | translate}}</span>
                </a>
                <a class="btn cursor-pointer btn-launcher btn-info rounded-full" (click)="$event.stopPropagation()" *ngIf="showLauncherButton" href="{{launcherLink}}" title="downloads">
                    <app-svg-icon [src]="'assets/svg/rocket-regular.svg'" [size]="'14px'"></app-svg-icon>
                    <span class="mx-1"></span>
                    <span>{{'Téléchargements' | translate}}</span>
                </a>
            </div>



            <!-- <div class="notifications position-relative mr-3" (click)="showLeft=false" *ngIf="accountService.isLogged()">
                <app-svg-icon class="cursor-pointer" [src]="'assets/svg/bell-regular.svg'" [size]="'25px'"></app-svg-icon>
                <div class="bg-danger notifBadge" *ngIf="notifs.notifications.unread">{{notifs.notifications.unread}}</div>
                <div class="notif-window d-none">
                    <app-notification-window *ngIf="accountService.isLogged()"></app-notification-window>
                </div>
            </div> -->
            <div class="account mr-3" (click)="showLeft=false">
                <div class="cursor-pointer d-flex align-items-center my-account" *ngIf="!accountService.isLogged()" (click)="$event.stopPropagation();appService.popupAccount();showLeft=false">
                  <app-svg-icon class="mr-2" [src]="'assets/svg/user-helmet-safety-light-bolder.svg'" [size]="'26px'"></app-svg-icon>
                  <span class="my-account-text">{{'Mon compte' | translate}}</span>
                </div>
                <a (click)="$event.stopPropagation()" [href]="language.getAccountink('overview')" class="d-flex align-items-center" title="account">
                    <img *ngIf="accountService.isLogged()" src="{{accountService.userInfos.avatar}}" width="25px" class="rounded-circle cursor-pointer mr-1" alt="Avatar of your account" title="Account" loading="lazy">
                    <div style="color:#000000;line-height:15px" class="d-none d-md-block">{{accountService.userInfos.name}}</div>
                </a>
                <div *ngIf="accountService.isLogged()" class="userAccount position-relative d-flex">
                    <div class="account-panel">
                        <img class="account-panel-avatar pb-1 pt-1" src="{{accountService.userInfos.avatar}}" alt="" loading="lazy">
                        <div class="account-panel-infos">
                            <div class="account-panel-account">
                                <h6 class="font-weight-bold m-0 color-blue-immersive">{{accountService.userInfos.name}}</h6>
                                <p class="text-muted m-0">{{accountService.userInfos.mail}}</p>
                            </div>
                            <div class="account-panel-link">
                                <a (click)="$event.stopPropagation()" [href]="language.getAccountink('overview')" title="my account">{{'Mon compte' | translate}}</a>
                            </div>
                        </div>
                        <hr class="m-0">
                        <div class="account-panel-logout" (click)="$event.stopPropagation();accountService.logout()">
                            {{'Se déconnecter' | translate}}
                        </div>
                    </div>
                    <div class="account-panel-triangle"></div>
                </div>
            </div>
            <lang-flag></lang-flag>

        </div>

    </nav>
</header>
