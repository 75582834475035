import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterSettingService {

  constructor(
    private httpClient: HTTPClientService,
  ) { }

  getAllRegisterSetting(){
    return this.httpClient.get('registerSetting')
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getByIdRegisterSetting(id){
    return this.httpClient.get('registerSetting/registerId')
      .then((response: any) => { return response })
      .catch(console.error);
  }
}
