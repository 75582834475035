import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({providedIn: 'root'})
export class FaqService {

    constructor(
        private httpClient: HTTPClientService
    ) { }

    getAllFaqs() {
        return this.httpClient.get('faq')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getTop5(){
        return this.httpClient.get('faq/top5')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getYoutubePlaylist(id) {
        return this.httpClient.get('datas/yt-playlist/'+id)
        .then((response: any) => { return response })
        .catch(console.error);
    }

}
