import { Component, OnInit, Input } from '@angular/core';
import { CacheService } from '../../services/cache.service'
import { HttpClient} from "@angular/common/http";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare var $:any; import {isBrowser} from '../../thirdparty/isBrowser'

@Component({
    selector: 'app-svg-icon',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.css']
})
export class SvgIconComponent implements OnInit {

    @Input() src = null;
    @Input() size = null;

    public svgDatas:any = '<span></span>'

    constructor(
        public http : HttpClient,
        public cache : CacheService,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        if(this.src){
            if(this.cache[this.src]){
                this.svgDatas = this.cache[this.src]
            }
            else{
                return new Promise((resolve)=>{
                    this.http.get(this.src, {responseType : 'text'})
                    .subscribe(
                        data => resolve(data),
                        err => resolve({httpError:err})
                    );
                })
                .then((response: any) => {
                    var res = this.sanitizer.bypassSecurityTrustHtml(response)
                    this.svgDatas = res
                    this.cache[this.src] = res
                })
            }
        }
    }

}
