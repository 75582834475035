import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-carousel-people',
  templateUrl: './carousel-people.component.html',
  styleUrls: ['./carousel-people.component.scss']
})
export class CarouselPeopleComponent implements OnInit {

  @Input() slides;
  currentSlide = 0;

  constructor() { }

  ngOnInit(): void {
    this.currentSlide = Math.floor(Math.random() * this.slides.length)
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }

}
