import { Component, OnInit, Input } from '@angular/core';
import { NewsService } from '../../services/news.service';
import { LanguageService } from '../../services/language.service';
import { DomSanitizer} from '@angular/platform-browser';

declare var $:any
import {isBrowser} from '../../thirdparty/isBrowser'

@Component({
    selector: 'app-actualite-card',
    templateUrl: './actualite-card.component.html',
    styleUrls: ['./actualite-card.component.scss']
})
export class ActualiteCardComponent implements OnInit {

    @Input() postInfos = null
    @Input() isRd = false
    @Input() featuredPost = false

    public post = null

    constructor(
        public newsService: NewsService,
        private _sanitizer: DomSanitizer,
        public language : LanguageService
    ) { }

    ngOnInit(): void {
        this.post = JSON.parse(JSON.stringify(this.postInfos))
        if(!this.post['link']) {
            if(this.isRd){
                this.post['link'] = '/research-innovation/'+this.newsService.getPostLink(this.post)
            }
            else{
                this.post['link'] = '/blog/'+this.newsService.getPostLink(this.post)
            }

        }
        if(!this.post['img']) { this.post['img'] = this.newsService.getNewsHeader(this.post['imageHeader']) }
        if(!this.post['shortDesc']){
            if(this.post['html']){
                this.newsService.getNewsHTML(this.post['html'])
                .then((res) =>{
                    if(res){
                        this.post['shortDesc'] = res.replace(new RegExp("<.[^<>]*>", "gi" )," ");
                    }
                    else{
                        this.post['shortDesc'] = '...'
                    }
                })
            }
            else{
                this.post['shortDesc'] = '...'
            }
        }
        if(this.featuredPost==true && this.post['html']){
          this.newsService.getNewsHTML(this.post['html'])
          .then((res) =>{
              if(res && isBrowser()){
                this.post['htmlContent'] = this._sanitizer.bypassSecurityTrustHtml(res)
                this.post['longDescription'] = this.extractTextFromArticle(this.post['htmlContent']);
              }
          })
        }
    }

    // On récupère une certaine longueur maximale des paragraphes de l'article
    extractTextFromArticle(articleContent: string, maxLength = 480) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(articleContent, 'text/html');
      let text = '';
      const paragraphElements = doc.querySelectorAll('p');
      const paragraphElementsArray = Array.from(paragraphElements);
      paragraphElementsArray.some((element) => {
          text += element.textContent.trim() + ' ';
          if (text.length >= maxLength) {
              text = text.slice(0, maxLength) + '...';
              return true;
          }
          return false;
      });
      return text.trim();
    }

}
