import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/environment';


@Injectable({providedIn: 'root'})
export class NewsService {

    constructor(
        private httpClient: HTTPClientService,
        public language : LanguageService,
        public translate : TranslateService
    ) { }


    getNewss(page=0, limit=10){
        return this.httpClient.get('news/posts/?order=created_date&orderDirection=DESC&nb='+limit+'&page='+page)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getNews(id): Promise<any> {
        return this.httpClient.get('news/getNews/'+id)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getNewsByTag(idT,idA,offset=0, limit=3): Promise<any> {
        return this.httpClient.get('news/getNewsByTag/'+idT+'/'+idA+'/'+offset+'/'+limit)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getPostsByTag(tagId, page=0, limit=10){
        return this.httpClient.get('news/posts/?tag_id='+tagId+'&order=created_date&orderDirection=DESC&nb='+limit+'&page='+page+'&lang='+this.translate.currentLang)
        .then((response: any) => { return response })
        .catch(console.error);
    }
// Pour l'image -> pas besoin de then quand je l'utiliserai dans construction
    getNewsHeader(img_url): string {
        return environment.dataStorage + img_url;
    }

    getNewsHTML(html_url) {
        return this.httpClient.get(environment.dataStorage+html_url, true)
        .then((response: any) => { return response })
        .catch((e)=>{
            return  '...'
        })
    }

    getFooterNews() {
        return this.httpClient.get('news/getNewss/0/5')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getTags(emptyTags=false){
        return this.httpClient.get('news/getTags/'+emptyTags)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getPostLink(post){
        var postLink = post['id']+"-"+post['title']
        postLink = postLink.replace(/[^a-zA-Z0-9-_çïëüâêîôûèàùé ]/g, '').replace(/[\s]{2,}/g," ").trim().replace(/ /g, '-')
        return postLink
    }

    formate(newsList){
        var newList = []
        for(let news of newsList){
            if(!news.imageHeader.includes('http://') && !news.imageHeader.includes('https://')){
                news.imageHeader = this.getNewsHeader(news.imageHeader)
            }

            news['link'] = news['id']+"-"+news['title']
            news['link'] = news['link'].replace(/[^a-zA-Z0-9-_çïëüâêîôûèàùé ]/g, '')
            news['link'] = news['link'].replace(/[\s]{2,}/g," "); // Enlève les espaces doubles, triples, etc.
            news['link'] = news['link'].replace(/^[\s]/, ""); // Enlève les espaces au début
            news['link'] = news['link'].replace(/[\s]$/,""); // Enlève les espaces à la fin
            news['link'] = news['link'].replace(/ /g, '-')


            if(this.language.langs.indexOf(this.translate.store.currentLang)>0){
                news['link']+='-'+this.translate.store.currentLang
            }

            var date = new Date(news['createdDate']);
            news['originalDate'] = date
            news['editDate'] = date.getFullYear().toString()+"-";
            if(date.getMonth().toString().length == 1){news['editDate'] += "0"+(date.getMonth()+1).toString()+"-";}
            else{ news['editDate'] += (date.getMonth()+1).toString()+"-";}
            if(date.getDate().toString().length == 1){news['editDate'] += "0"+(date.getDate()).toString();}
            else{news['editDate'] += (date.getDate()).toString(); }

            news['createdDate'] = new Date(news['createdDate']);
            news['createdDate'] = new Date(news['createdDate']).setDate(news['createdDate'].getDate());


            news['createdDate'] = this.language.getDate(news['createdDate'])


            if(news.tag){
                news['tagTitle'] = news.tag.title;
                news['tagId'] = news.tag.id;
            }

            news['htmlLink'] = environment.dataStorage+news['html'];
            if(news.html){
                this.getNewsHTML(news.html).then(res =>{
                    news['html'] = res;
                    news['htmlLink'] = news.html;
                    var httw = "";
                    if(res){
                        httw = res.replace(new RegExp("<.[^<>]*>", "gi" ),"").split(' ');
                        news['shortDesc'] = "";
                        for (var i=0;i<40;i++) {
                            if(httw[i] != undefined){
                                news['shortDesc'] += httw[i]+" ";
                            }
                        }
                        if(httw.length>40){
                            news['shortDesc'] +="...";
                        }
                    }
                })
            }

            newList.push(news)
        }
        return newList;
    }

    formateOneNews(oneNews){
            if(oneNews){
                if(!oneNews.imageHeader.includes('http://') && !oneNews.imageHeader.includes('https://')){
                    oneNews.imageHeader = this.getNewsHeader(oneNews.imageHeader)
                }

                oneNews['link'] = oneNews['id']+"-"+oneNews['title']
                oneNews['link'] = oneNews['link'].replace(/[^a-zA-Z0-9-_çïëüâêîôûèàùé ]/g, '')
                oneNews['link'] = oneNews['link'].replace(/[\s]{2,}/g," "); // Enlève les espaces doubles, triples, etc.
                oneNews['link'] = oneNews['link'].replace(/^[\s]/, ""); // Enlève les espaces au début
                oneNews['link'] = oneNews['link'].replace(/[\s]$/,""); // Enlève les espaces à la fin
                oneNews['link'] = oneNews['link'].replace(/ /g, '-')


                if(this.language.langs.indexOf(this.translate.store.currentLang)>0){
                    oneNews['link']+='-'+this.translate.store.currentLang
                }

                var date = new Date(oneNews['createdDate']);
                oneNews['originalDate'] = date
                oneNews['editDate'] = date.getFullYear().toString()+"-";
                if(date.getMonth().toString().length == 1){oneNews['editDate'] += "0"+(date.getMonth()+1).toString()+"-";}
                else{ oneNews['editDate'] += (date.getMonth()+1).toString()+"-";}
                if(date.getDate().toString().length == 1){oneNews['editDate'] += "0"+(date.getDate()).toString();}
                else{oneNews['editDate'] += (date.getDate()).toString(); }

                oneNews['createdDate'] = new Date(oneNews['createdDate']);
                oneNews['createdDate'] = new Date(oneNews['createdDate']).setDate(oneNews['createdDate'].getDate());


                oneNews['createdDate'] = this.language.getDate(oneNews['createdDate'])


                if(oneNews.tag){
                    oneNews['tagTitle'] = oneNews.tag.title;
                    oneNews['tagId'] = oneNews.tag.id;
                }

                oneNews['htmlLink'] = environment.dataStorage+oneNews['html'];

                console.log(oneNews)
                if(oneNews.html && !oneNews.html.includes('<') && !oneNews.html.includes('>')){
                    this.getNewsHTML(oneNews.html).then(res =>{
                        oneNews['html'] = res;
                        oneNews['htmlLink'] = oneNews.html;
                        var httw = "";
                        if(res){
                            httw = res.replace(new RegExp("<.[^<>]*>", "gi" ),"").split(' ');
                            oneNews['shortDesc'] = "";
                            for (var i=0;i<40;i++) {
                                if(httw[i] != undefined){
                                    oneNews['shortDesc'] += httw[i]+" ";
                                }
                            }
                            if(httw.length>40){
                                oneNews['shortDesc'] +="...";
                            }
                        }
                    })
                }

                if(oneNews['link']) {
                        oneNews['link'] = '/blog/'+this.getPostLink(oneNews);
                }
            }
            

        return oneNews;
    }
}
