import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({providedIn: 'root'})
export class HierarchieService {

  constructor(
      private httpClient: HTTPClientService
  ) { }

    getCompanies(){
        return this.httpClient.get('companys')
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getInferiorGroups(){
        return this.httpClient.get('companys/inferiorGroups')
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getCurrentGroup(){
        return this.httpClient.get('companys/currentGroup')
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getMyGroupReductions(){
        return this.httpClient.get('companys/currentGroup/reductions')
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getMembers(){
        return this.httpClient.get('companys/members')
          .then((response: any) => { return response })
          .catch(console.error);
    }

    sendKey(object){
        return this.httpClient.post('companys/sendKey', object)
          .then((response: any) => { return response })
          .catch(console.error);
    }

    getOtherReport(productId,accountId){
      return this.httpClient.get('companys/otherreport/'+productId+"/"+accountId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getMyDomainAccounts(){
        return this.httpClient.get('company/allusers')
          .then((response: any) => { return response })
          .catch(console.error);
    }

}
