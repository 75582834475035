import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'vr-logo',
	template: `
		<div [outerHTML]="html"></div>
	`,
})
export class VrLogoComponent implements OnInit {
	constructor(private sanitizer: DomSanitizer) {}

	@Input() size = 35;

    html:SafeHtml = ''

	ngOnInit() {
        this.html = this.getIconHTML()
    }

    getIconHTML(sanitized = true){
        const html = `
            <svg 
                width="${this.size}"
                height="${this.size}"
                xmlns="http://www.w3.org/2000/svg" 
                xmlns:xlink="http://www.w3.org/1999/xlink" 
                version="1.1" 
                viewBox="0 0 37 37"
            >
                <defs>
                    <style>
                    .cls-1 {
                        fill: url(#Dégradé_sans_nom);
                    }

                    .cls-1, .cls-2 {
                        stroke-width: 0px;
                    }

                    .cls-3 {
                        isolation: isolate;
                    }

                    .cls-2 {
                        fill: #fff;
                    }
                    </style>
                    <radialGradient id="Dégradé_sans_nom" data-name="Dégradé sans nom" cx="18.5" cy="18.5" fx="18.5" fy="18.5" r="18.5" gradientTransform="translate(0 37) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#f3858c"/>
                    <stop offset="1" stop-color="#ed1c24"/>
                    </radialGradient>
                </defs>
                <rect class="cls-1" width="37" height="37" rx="3" ry="3"/>
                <g class="cls-3">
                    <g class="cls-3">
                    <path class="cls-2" d="M18,10.9l-4.5,15.3h-3.6l-4.5-15.3h3.3l3,10.7,3-10.7h3.3Z"/>
                    <path class="cls-2" d="M31.6,26.1h-3.9l-3.4-5.6h-1.5v5.6h-3.2v-15.3h5.3c.7,0,1.4,0,1.9.2.5.1,1,.3,1.5.7.5.3.8.8,1.1,1.3.3.5.4,1.2.4,2s-.2,2-.6,2.8c-.4.7-1,1.3-1.8,1.8l4.1,6.5ZM26.7,15.5c0-.4,0-.7-.2-1s-.3-.5-.7-.6c-.2-.1-.5-.2-.8-.2-.3,0-.6,0-1,0h-1.2v4.1h1c.5,0,.9,0,1.3,0s.6-.2.9-.4c.2-.2.4-.4.5-.7.1-.3.2-.6.2-1Z"/>
                    </g>
                </g>
            </svg>
        `
        return sanitized ? this.sanitizer.bypassSecurityTrustHtml(html) : html
    }
}
