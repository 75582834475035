import { Component, OnInit, OnDestroy, forwardRef,Input,ElementRef, ViewChild,AfterViewInit, Output,EventEmitter} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor,NgModel } from '@angular/forms';

declare var $:any; import {isBrowser} from '../../thirdparty/isBrowser'
declare var intlTelInput:any

const customValueProvider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IntlTelInputComponent),
    multi: true
};

@Component({
    selector: 'app-intl-tel-input',
    templateUrl: './intl-tel-input.component.html',
    styleUrls: ['./intl-tel-input.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IntlTelInputComponent),
            multi: true
        }
    ]


})
export class IntlTelInputComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {

    @ViewChild("phone", {static: false}) htmlElement: ElementRef;

    _value = '';

    propagateChange:any = () => {};

    @Input() label: string;

    @Input() initialCountry:string = "US";

    @Input() preferredCountries:string[] = ["FR","US"];

    @Input() initialValue:string="";

    @Output() public domChange = new EventEmitter();

    public inputValue:string= "";

    public inputClassList:string="";

    private changes: MutationObserver;

    public iti = null

    constructor(private elRef:ElementRef) {
        if (typeof MutationObserver !== "undefined") {
            this.changes = new MutationObserver((mutations: MutationRecord[]) => {
                mutations.forEach((mutation: MutationRecord) => this.domChange.emit(mutation));
            });

            this.changes.observe(this.elRef.nativeElement, {
                attributes: true
            });
        }


    }

    ngOnInit() {
    }


    ngAfterViewInit() {
        //bug fix for aot-compiling
        if(isBrowser()) $('.allow-dropdown').css("width","100%")
        this.iti = null
        if(isBrowser()) this.iti = intlTelInput(
            $('#phone')[0],
            {
                "initialCountry":this.initialCountry,
                "preferredCountries":this.preferredCountries,
                "nationalMode":"true"
            }
        )
        if(isBrowser()) $(this.htmlElement.nativeElement).addClass(this.elRef.nativeElement.className);
        var name=null
        if(isBrowser()) name = $(this.elRef.nativeElement).attr("name");
        if(name){
            if(isBrowser()) $(this.htmlElement.nativeElement).attr("name",'name')
        }


        this.domChange.subscribe(()=>{
            this.inputClassList = this.elRef.nativeElement.className;
        })
    }


    ngOnDestroy(): void {
        if(isBrowser())this.changes.disconnect();
    }

    writeValue(value: any) {
        if( value ){
            this.iti.setNumber(value)
            this.iti.getNumber()
            this._value = this.iti.getNumber()
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void { }

    onChange(event){

        if(this.iti.isValidNumber()){
            this.propagateChange(this.iti.getNumber());
        }
        else{
            this.propagateChange(null);
        }
    }

}
