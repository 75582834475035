import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({providedIn: 'root'})
export class SubscriptionsService {

    constructor(
        private httpClient: HTTPClientService
    ) { }

    getAllSubscriptions(){
        return this.httpClient.get('subscriptions')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getSubscriptionById(subId){
        return this.httpClient.get('subscription/'+subId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getSafetySubscriptions(){
        return this.httpClient.get('subscriptions/safety')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    safetySubscribe(basket, payment, infos){
        return this.httpClient.post('subscriptions/subscribe', {basket:basket, payment:payment, infos:infos})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    checkInvoiceStatus(invoiceId){
        return this.httpClient.get('subscriptions/invoice-status/'+invoiceId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    cancelInvoice(invoiceId){
        return this.httpClient.delete('subscriptions/stripe/'+invoiceId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getSubscription(){
        return this.httpClient.get('subscriptions/mine/')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    deleteUserFromSubscription(spaceId, accountId){
        return this.httpClient.delete('subscriptions/members/'+spaceId+'/'+accountId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    addUserToSubscription(spaceId, accountId){
        return this.httpClient.post('subscriptions/members/', {spaceId:spaceId, accountId:accountId})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    testFuturePricing(subId, quantity){
        return this.httpClient.post('subscriptions/test', {subId:subId,quantity:quantity})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    upgradeSubscription(subId, quantity){
        return this.httpClient.post('subscription/upgrade', {subId:subId,quantity:quantity})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    cancelSubscription(){
        return this.httpClient.post('subscription/cancel', {})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    changeAutoRenew(subId, newValue){
        return this.httpClient.post('subscription/autorenew', {subId:subId, newValue:newValue})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getTrainerCampusSubscription(){
        return this.httpClient.get('subscriptions/trainer-campus/with-no-uses')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    

}
