import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import {isBrowser} from '../../thirdparty/isBrowser'

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {

    @Input() slides = [];
    currentSlide = 0;
    private mySub = null;

    constructor(
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {
        if(isBrowser()){
            this.mySub = setInterval(()=>{
                this.onNextClick();
            }, 4000)
        }
    }

    ngOnDestroy(){
        if(isBrowser()){
            clearInterval(this.mySub)
            this.mySub = null
        }
    }

    onPreviousClick() {
        if(this.slides && Array.isArray(this.slides) && this.slides.length){
            const previous = this.currentSlide - 1;
            this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
        }
    }

    onNextClick() {
        if(this.slides && Array.isArray(this.slides) && this.slides.length){
            const next = this.currentSlide + 1;
            this.currentSlide = next === this.slides.length ? 0 : next;
        }
    }

}
