import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "../../../environments/environment";
import { isBrowser } from '../thirdparty/isBrowser'
declare var $:any;

import * as axios from 'axios'


@Injectable({providedIn: 'root'})
export class InterceptedHttp{

    private newTimer: any;
    public timeSub : any = null;
    public LoaderEnabled = true;
    public nbReq = 0;
    public waitingTime : number = 1000;
    public testConsole : boolean = true; //Affichage de l'historique dans la console

    constructor(
        public http : HttpClient
    ) {
    }

    get(url, options?) {
        url = this.updateUrl(url);
        return new Promise((resolve, reject)=>{
            this.http.get(url, this.getRequestOptionArgs(options))
            .subscribe(
                data => resolve(data),
                err => {
                    // console.error(url,err)
                    reject({httpError:err})
                }
            );
        })
    }

    post(url, body: any, options?) {
        url = this.updateUrl(url);
        var formData = body
        return new Promise((resolve, reject)=>{
            this.http.post(url, body, this.getRequestOptionArgs(options,formData))
            .subscribe(
                data => resolve(data),
                err => {
                    // console.error(err)
                    reject({httpError:err})
                }
            );
        })
    }

    put(url, body, options?) {
        url = this.updateUrl(url);
        return new Promise((resolve, reject)=>{
            this.http.put(url, body, this.getRequestOptionArgs(options))
            .subscribe(
                data => resolve(data),
                err => {
                    // console.error(err)
                    reject({httpError:err})
                }
            );
        })
    }

    delete(url, options?) {
        url = this.updateUrl(url);
        return new Promise((resolve, reject)=>{
            this.http.delete(url, this.getRequestOptionArgs(options))
            .subscribe(
                data => resolve(data),
                err => {
                    // console.error(err)
                    reject({httpError:err})
                }
            );
        })
    }

    public updateUrl(req) {
        var request = req;
        if(req.indexOf("http://") != -1 || req.indexOf("https://") != -1){
            request= req;
        }else{
            if(isBrowser()){
                request=environment.localbackend + req;
            }
            else{
                request=environment.localbackend + req;
            }

        }
        return request;
    }

    private getRequestOptionArgs(options, formData=null)  {
        var headers = {}

        for(let k of Object.keys(options['headers'])){
            if(options['headers'][k]){
                headers[k] = options['headers'][k]
            }
        }
        if(!formData){
            headers['content-type'] = 'application/json'
        }

        var toRes = {headers : new HttpHeaders(headers)}
        for(let k2 of Object.keys(options).filter(e => e != 'headers')){
            toRes[k2] = options[k2]
        }
        return toRes;
    }

    enableLoader(){
        this.LoaderEnabled = true;
        this.nbReq=0;

    }

    disableLoader(){
        this.LoaderEnabled = false;
    }
}
