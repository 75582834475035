<!-- carousel -->
<!-- <div class="position-absolute img">
  <img *ngFor="let slide of slides; let i = index" [src]="slide.src">
</div> -->
<!-- *ngIf="i === currentSlide && slide.src" -->

  <div class="container people-carousel px-0 py-0" *ngIf="slides">
      <ng-container class="px-0 py-0" *ngFor="let slide of slides; let i = index">
        <picture *ngIf="slide.name && slide.webp && slide.srcType">
            <source [srcset]="slide.webp" type="image/webp">
            <source [srcset]="slide.src" [type]="slide.srcType">
            <img [src]="slide.src" [alt]="slide.name" [title]="slide.name" class="slide" [ngClass]="{'d-block' : i == currentSlide && slide.src}" loading="lazy"/>
        </picture>
        <img *ngIf="slide.name && (!slide.webp || !slide.srcType)" [ngClass]="{'d-block' : i == currentSlide && slide.src}"
          [src]="slide.src"
          [alt]="slide.name"
          [title]="slide.name"
          class="slide"
          loading="lazy"
        />
        <img *ngIf="!slide.name && (!slide.webp || !slide.srcType)" [ngClass]="{'d-block' : i == currentSlide && slide.src}"
          [src]="slide.src"
          alt = "EHS VR training"
          title = "EHS training"
          class="slide"
          loading="lazy"
        />
        <picture *ngIf="!slide.name && slide.webp && slide.srcType">
            <source [srcset]="slide.webp" type="image/webp">
            <source [srcset]="slide.src" [type]="slide.srcType">
            <img [src]="slide.src" alt = "EHS VR training" title = "EHS training" class="slide" [ngClass]="{'d-block' : i == currentSlide && slide.src}" loading="lazy"/>
        </picture>
      </ng-container>
      <div class="fleches">
    <!-- Carousel rows --> <a class="carousel-control left carousel-control-prev" (click)="onPreviousClick()" *ngIf="slides && slides?.length > 1"> <i class="fa fa-angle-left"></i> </a> <a class="carousel-control right carousel-control-next" (click)="onNextClick()" *ngIf="slides && slides?.length > 1"> <i class="fa fa-angle-right"></i> </a>
    </div>
</div>
