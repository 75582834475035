import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { LanguageService } from "../../services/language.service";


declare var $:any;
import { isBrowser } from '../../thirdparty/isBrowser'

@Component({
    selector: 'webgl-build',
    templateUrl: './webgl-build.component.html',
    styleUrls: ['./webgl-build.component.scss']
})
export class WebglBuildComponent implements OnInit, OnDestroy {

    @Output() eventEmit: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() changeMouseStatus: EventEmitter<Object> = new EventEmitter<Object>();

    @Input() overlayImg = null
    @Input() overlayText = null
    @Input() loadingText = null
    @Input() appUrl = null
    @Input() step = 'overlay'
    @Input() iframeLogs = true

    public iframe:any = null
    public pointerLocked = false

    constructor(
        public language : LanguageService
    ) { }

    ngOnInit(): void {
        if(isBrowser()){
            window.addEventListener("message", (e)=>{ this.receiveMessage(e) }, false);
        }
    }

    ngOnDestroy(){
        if(isBrowser()){
            window.removeEventListener("message", (e)=>{ this.receiveMessage(e) }, false);
        }
    }

    playGame(){
        if(isBrowser()){
            this.eventEmit.emit('if-webglapp-started')
            this.changeStep('loading')
            this.iframe = document.createElement('iframe');
            this.iframe.setAttribute("src", this.appUrl+'?lang='+this.language['lang']);
            this.iframe.setAttribute("id", 'WebGLApp');
            document.getElementById('iframe-container').appendChild(this.iframe);
            if(!this.iframeLogs){
                this.iframe.contentWindow.console.log = function() { };
                this.iframe.contentWindow.console.error = function() { };
                this.iframe.contentWindow.console.warn = function() { };
            }
        }
    }

    receiveMessage(event){
        if(event['data'] && typeof event['data'] == 'string' && event['data'].slice(0,12)=='if-webglapp-'){
            if(event['data']=='if-webglapp-loaded'){
                this.eventEmit.emit('if-webglapp-loaded')
                this.changeStep('playing')
            }
            else if(event['data']=='if-webglapp-run-finished'){
                this.eventEmit.emit('if-webglapp-run-finished')
                this.iframe.contentWindow.removeMouseLock()
            }
            else if(event['data']=='if-webglapp-lock-pointer'){
                this.eventEmit.emit('if-webglapp-lock-pointer')
                this.changePointerLock(true)
            }
            else if(event['data']=='if-webglapp-unlock-pointer'){
                this.eventEmit.emit('if-webglapp-unlock-pointer')
                this.changePointerLock(false)
            }
        }
    }

    changePointerLock(value){
        if(this.pointerLocked != value){
            this.pointerLocked = value
            this.changeMouseStatus.emit(this.pointerLocked)
        }
    }

    clickOnApp(){
        $('').click()
    }

    changeStep(step){
        if(isBrowser()){
            this.hideOverlay()
            .then(()=>{
                this.step = step
                if(step == 'loading' && this.loadingText){
                    this.showOverlay()
                }
            })
        }
    }

    showOverlay(){
        if(isBrowser()){
            return new Promise((resolve)=>{
                setTimeout(() => {
                    $('#overlay-texts').css('display', 'flex')
                    setTimeout(() => {
                        $('#overlay-texts').css('opacity', '1')
                        resolve()
                    }, 50);
                }, 50);
            })
        }
        else{
            return Promise.resolve()
        }

    }
    hideOverlay(){
        if(isBrowser()){
            return new Promise((resolve)=>{
                $('#overlay-texts').css('opacity', '0')
                setTimeout(() => {
                    $('#overlay-texts').css('display', 'none')
                    resolve()
                }, 300);
            })
        }
        else{
            return Promise.resolve()
        }
    }

    endGame(){
        if(isBrowser()){
            $('#iframe-container').css('opacity','0')
            setTimeout(() => {
                $('#iframe-container').empty()
            }, 300);
        }
    }

    replay(){
        if(isBrowser()){
            $('#iframe-container').css('opacity','1')
            setTimeout(() => {
                this.playGame()
            }, 100);
        }
    }

}
