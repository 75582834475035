import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({providedIn: 'root'})
export class ProdTagService {

    constructor(private httpClient: HTTPClientService) { }

    getTags(tags=undefined, offset=0, limit=1){
        return this.httpClient.post('search/product/tags/'+offset+'/'+limit, {tags:tags})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getAllProdTags(){
        return this.httpClient.get('products/prodtags')
        .then((response: any) => { return response })
        .catch(console.error);
    }


}
