import { Injectable, EventEmitter, Output } from '@angular/core';
import { HTTPClientService } from './httpclient.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from './products.service';
import { ClientOrderService } from './clientorder.service';
import { UtilsService } from './utils.service';
import { Router } from '@angular/router';
import { LocalstorageService } from './localstorage.service'

declare var Swal:any;

@Injectable({providedIn: 'root'})
export class BasketService {

    public basket = null;
    public billingInformation:any = null;

    public showPopup = false;

    public currentCurrency = "EUR"
    public currencySymbol = "€"

    public conversionArray = {
        "EUR" : {
            string:"euros",
            symbol:"€",
            euroConversion:"1",
            icon: "euro-sign"
        },
        "USD" : {
            string:"american dollar",
            symbol:"$",
            euroConversion:"1.2",
            icon: "dollar-sign"
        },
        "GBP" : {
            string:"pound sterling",
            symbol:"£",
            euroConversion:"0.9",
            icon: "pound-sign"
        },
        "CAD" : {
            string:"canadian dollar",
            symbol:"$",
            euroConversion:"1.5",
            icon: "dollar-sign"
        }
    }

    /* ------- */

    public maxCampusSize = 100
    public maxFeesSize = 10000

    public defaultQuantities = {
        users_subscription : 100,
        campus : 10,
        workshops : 1,
        workshop : 1,
        trainer_campus: 10
    }

    public workshopsLimits = {
        annual : 13,
        monthly : 50
    }

    public defaultPeriods = {
        users_subscription : 'annual',
        campus : 'monthly',
        workshops : 'annual',
        workshop : 'annual'
    }

    constructor(
        private productService: ProductsService,
        private httpClient: HTTPClientService,
        public translate : TranslateService,
        public clientOrderService : ClientOrderService,
        public utilsService : UtilsService,
        public router : Router,
        public localStorage : LocalstorageService
    )
    {
        this.basket = null;
        if(this.localStorage && this.localStorage.getItem('currency')){
            this.changeCurrency(this.localStorage.getItem('currency'))
        }
        if(this.localStorage && this.localStorage.getItem('basket')){
            try {
                var tmpBasket = JSON.parse(this.localStorage.getItem('basket'))
                if(!Array.isArray(tmpBasket) && typeof tmpBasket == 'object'){
                    this.basket = tmpBasket
                }
            } catch (e) {
                this.localStorage.removeItem('basket')
            }
        }
    }

    saveBasket() {
        this.localStorage.setItem('basket', JSON.stringify(this.basket))
    }

    clearBasket() {
        this.basket = null
        this.localStorage.removeItem('basket')
    }

    changeCurrency(currencyCode=null){
        if(currencyCode){
            if(this.conversionArray[currencyCode]){
                this.currentCurrency = currencyCode
                this.currencySymbol = this.conversionArray[currencyCode].symbol
                this.localStorage.setItem('currency', currencyCode)
            }
        }
        else{
            this.changeCurrency("USD")
        }
    }

    calculateSafetyBasket(type, quantity, subscriptions, groupDiscount, accountInfos){
        var prices = {
            subtotal : 0,
            total : 0
        }
        if(!type.includes('day_') && !type.includes('university_')){
            var sub = subscriptions[type]
            prices['subtotal'] = quantity * sub['price']

            if(sub['tiers_mode'] && sub['tiers_mode']=='volume'){
                var priceToApply = null
                var keepedIndex = 0
                for(let tier of sub['tiers']){
                    if((quantity<=tier['up_to'] && !priceToApply) || (!priceToApply && !tier['up_to'])){
                        priceToApply = tier['unit_amount']
                    }
                }
                prices['quantity_discount'] = prices['subtotal'] - (quantity * priceToApply/100)
                prices['total'] = quantity * priceToApply/100
            }
            else if(sub['tiers_mode'] && sub['tiers_mode']=='graduated'){
                var totalPrice = 0
                var floor = 0
                for(let tier of sub['tiers']){
                    for (let x = 1; x < quantity+1; x++) {
                        if(((floor<x && x<=tier['up_to']) && tier['up_to']) ||(floor<x && !tier['up_to'])){
                            totalPrice+= tier['unit_amount']/100
                        }
                    }
                    floor = tier['up_to']
                }
                prices['quantity_discount'] = prices['subtotal'] - totalPrice
                prices['total'] = totalPrice
            }
            else{
                prices['total'] = quantity * sub['price']
            }

            if(groupDiscount){
                prices['group_discount'] = this.utilsService.roundPrices(prices['total']*(groupDiscount/100), 2)
                prices['total'] = prices['total'] - prices['group_discount']
            }

            if(accountInfos['country'] && accountInfos['country'] == 'fr'){
                prices['vat'] = this.utilsService.roundPrices(prices['total']*0.2, 2)
                prices['total'] = this.utilsService.roundPrices(prices['total'] + prices['vat'], 2)
            }
        }

        return prices
    }

    getPrices(){
        return this.httpClient.get('prices')
        .then((response: any) => { return response })
        .catch((response: any) => { return response })
    }

    checkout(informations, basket){
        return this.httpClient.post('checkout', {informations:informations, basket:basket})
        .then((response: any) => { return response })
        .catch((response: any) => { return response })
    }

    analyzeBasket(){
        return this.getPrices()
        .then((prices)=>{
            if(prices){
                var basket:any = []
                var promoCode = null
                var toRes = {}
                try {
                    var storedBasket = JSON.parse(this.localStorage.getItem('basket'))
                    toRes = ((storedBasket) ? storedBasket : {})
                } catch (e) {
                    return this.emptyCart()
                    toRes = {}
                }
                toRes['prices'] = prices
                return new Promise((resolve)=>{
                    if(toRes['items'] && Array.isArray(toRes['items']) && toRes['items'].length){
                        if(toRes['items'].find(e => e['type'] == 'campus') && toRes['items'].find(e => e['type'] == 'users_subscription')){
                            var line1 = toRes['items'].find(e => e['type'] == 'campus')
                            var line2 = toRes['items'].find(e => e['type'] == 'users_subscription')
                            toRes['basketType'] = 'campus'
                            resolve([
                                {
                                    type : 'campus',
                                    quantity : line1['quantity'] || this.defaultQuantities['campus'],
                                    period : line1['period'] || this.defaultPeriods['campus'],
                                },
                                {
                                    type : 'users_subscription',
                                    quantity : line2['quantity'] || this.defaultQuantities['users_subscription'],
                                    period : this.defaultPeriods['users_subscription'],
                                }
                            ])
                        }
                        else if(toRes['items'].find(e => e['type'] == 'workshops')){
                            var line1 = toRes['items'].find(e => e['type'] == 'workshops')
                            toRes['basketType'] = 'workshops'
                            resolve([{
                                type : 'workshops',
                                quantity : line1['quantity'] || this.defaultQuantities['workshops'],
                                period : line1['period'] || this.defaultPeriods['workshops'],
                            }])
                        }
                        else if(toRes['items'].find(e => e['type'] == 'workshop')){
                            var line1 = toRes['items'].find(e => e['type'] == 'workshop')
                            toRes['basketType'] = 'workshop'
                            if(line1['referenceId']){
                                this.productService.getProductById(line1['referenceId'])
                                .then((product)=>{
                                    if(product && product['level'] == 'Disponible' && !product['companyGroupId'] && !['WEBGL'].includes(product['type'])){
                                        toRes['product'] = product
                                        resolve([{
                                            type : 'workshop',
                                            referenceId : line1['referenceId'],
                                            quantity : line1['quantity'] || this.defaultQuantities['workshop'],
                                            period : line1['period'] || this.defaultPeriods['workshop'],
                                            product : product
                                        }])
                                    }
                                    else{
                                        resolve([])
                                    }
                                })
                            }
                            else{
                                resolve([])
                            }
                        }
                        else if(toRes['items'].find(e => e['type'] && e['type'].toString() == 'trainer_campus')){
                            var line1 = toRes['items'].find(e => e['type'].toString() == 'trainer_campus')
                            toRes['basketType'] = 'trainer_campus'
                            resolve([{
                                type : 'trainer_campus',
                                referenceId : (["5","10"].includes(line1['referenceId'].toString()) ? line1['referenceId'] : this.defaultQuantities.trainer_campus).toString(),
                                referenceId2 : (["VR","PC"].includes(line1['referenceId2'].toString()) ? line1['referenceId2'] : "VR"),
                                period : 'daily',
                                quantity : 1,
                            }])
                        }                        
                        else{
                            resolve([])
                        }
                    }
                    else{
                        resolve([])
                    }
                })
                .then((newBasket)=>{
                    basket = newBasket
                    if(basket.length){
                        toRes['items'] = this.calculateBasket(basket, toRes['prices'])
                        return toRes
                    }
                    else{
                        return this.emptyCart()
                    }

                })
            }
            else{
                return this.emptyCart()
            }

        })
    }

    calculateBasket(basket, prices, promoCode=null){
        for(let item of basket){
            if(!item['quantity'] || isNaN(Number(item['quantity'])) || item['quantity']%1!=0 || Number(item['quantity']) <= 0){
                item['quantity'] = 1
            }
            else{
                item['quantity'] = Number(item['quantity'])
            }
            if(item['type']=='campus' && item['quantity']>this.maxCampusSize){
                item['quantity'] = this.maxCampusSize
            }
            item['unit'] = prices[item['type']+'_'+item['period']] || 0

            if(item['period'] == 'annual' && item['product'] && item['product']['priceYear'] && item['product']['priceYear']>0){
                item['unit'] = Number(item['product']['priceYear'])
            }
            else if(item['period'] == 'monthly' && item['product'] && item['product']['priceMonth'] && item['product']['priceMonth']>0){
                item['unit'] = Number(item['product']['priceMonth'])
            }
            if(item['type'] == 'trainer_campus'){
                item['unit'] = (prices[item['type']+'_'+(item['referenceId'] || this.defaultQuantities.trainer_campus)+'_'+item['period']]) || 0
            }

            item['total'] = item['unit']* item['quantity']
            if(['trainer_campus'].includes(item['type']) && promoCode && promoCode['reducType'] && promoCode['amount']){

                item['beforeReduc'] = item['total']
                item['reducType'] = promoCode['reducType']
                item['reducAmount'] = promoCode['amount']
                item['reducCode'] = promoCode['code']
                if(promoCode['reducType']=='%'){
                    item['reducToDecrease'] = item['total']*(promoCode['amount']/100)
                }
                if(promoCode['reducType']=='€'){
                    item['reducToDecrease'] = promoCode['amount']
                }
                item['total'] = item['total'] - item['reducToDecrease']
            }
        }
        return basket
    }

    emptyCart(){
        this.localStorage.setItem('basket', JSON.stringify({items:[]}))
        return { items : [] }
    }

    testPromoCode(promoCode){
        return this.httpClient.get('promocode/'+promoCode)
        .then((response: any) => { return response })
        .catch((response: any) => { return response })
    }

    getPaymentIntent(infos, basket){
        return this.httpClient.post('basket/askPayment', {infos : infos, basket : basket})
        .then((response: any) => { return response })
        .catch((response: any) => { return response })
    }

    setPaidByIntent(paymentId, infos, basket){
        return this.httpClient.post('basket/confirmPayment', {paymentId: paymentId, infos: infos, basket: basket})
        .then((response: any) => { return response })
        .catch((response: any) => { return response })
    }

}
