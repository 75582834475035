import { Component, OnInit, OnChanges, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
import {isBrowser} from '../../thirdparty/isBrowser'

@Component({
    selector: 'password-checker',
    templateUrl: './password-checker.component.html',
    styleUrls: ['./password-checker.component.scss']
})
export class PasswordCheckerComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() password = ''
    @Output() changeValidity: EventEmitter<Object> = new EventEmitter<Object>();

    public rules:any = [
        {
            name : this.translate.instant('8 caractères'),
            reg : /^.{8,}$/,
            valid : false
        },
        {
            name : this.translate.instant('Une majuscule'),
            reg : /[A-Z]/,
            valid : false
        },
        {
            name : this.translate.instant('Une minuscule'),
            reg : /[a-z]/,
            valid : false
        },
        {
            name : this.translate.instant('Un caractère spécial'),
            reg : /[\[\*\.\!\@\#\$\%\^\&\(\)\{\}\[\]\:\;\<\>\,\.\?\/\~\_\+\-\=\|\\\]]/,
            valid : false,
            infos : this.translate.instant('Parmi la liste suivante *.!@#$%^&(){}[]:;<>,.?/~_+-=|\'')
        }
    ]

    constructor(
        public translate : TranslateService
    ) { }

    ngOnInit(): void {

    }

    ngOnChanges(){
        this.checkRules()
    }

    ngAfterViewInit(){
        if(isBrowser()){
            $(function () {
                $('[data-toggle="tooltip"]').tooltip()
            })
        }
    }

    checkRules(){
        if(!this.password){
            this.password = ''
        }
        for(let rule of this.rules){
            if(rule['reg']){
                rule['valid'] = ((this.password.toString().match(rule['reg'])) ? true : false)
            }
            else{
                rule['valid'] = false
            }
        }
        this.changeValidity.emit(!this.rules.map(e => e['valid']).includes(false))
    }

}
