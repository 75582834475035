import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RegisterSettingService } from '../../services/register-setting.service'
import { AccountService } from '../../services/account.service'
import { ContactService } from '../../services/contact.service';
import { UtilsService } from '../../services/utils.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { isBrowser } from '../../thirdparty/isBrowser';
import { LanguageService } from '../../services/language.service';

declare var Swal:any;

@Component({
    selector: 'contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnChanges {

    private initialInputs = [
        {
            id : 'name',
            placeholder : this.translate.instant('Nom'),
            value : '',
            error : null,
            type : 'text',
            options : []
        },
        {
            id : 'company',
            placeholder : this.translate.instant('Entreprise'),
            value : '',
            error : null,
            type : 'text',
            options : []
        },
        {
            id : 'mail',
            placeholder : this.translate.instant('Email'),
            value : '',
            error : null,
            type : 'email',
            options : []
        },
        {
            id : 'phone',
            placeholder : this.translate.instant('Téléphone'),
            value : '',
            error : null,
            type : 'phone',
            options : []
        },
        // {
        //     id : 'interest',
        //     placeholder : 'Je suis intéressé(e) par',
        //     value : '',
        //     error : null,
        //     type : 'select',
        //     options : [
        //         // {
        //         //     value : "event",
        //         //     label : 'L’évènement ou la conférence'
        //         // },
        //         // {
        //         //     value : "whitepaper",
        //         //     label : 'Le dernier livre blanc'
        //         // },
        //         // {
        //         //     value : "testimony",
        //         //     label : 'Les témoignages clients'
        //         // },
        //         // {
        //         //     value : "partner",
        //         //     label : 'Un partenariat revendeur'
        //         // },
        //         {
        //             value: "demo",
        //             label: 'Démo en ligne'
        //         },
        //         {
        //             value: "call",
        //             label: 'Etre rappelé'
        //         },
        //         {
        //             value: "formation_offers",
        //             label: 'Offres de formations'
        //         },
        //         {
        //             value : "safetyday",
        //             label : 'La journée sécurité'
        //         }
        //     ]
        // },
        {
            id : 'message',
            placeholder : this.translate.instant('Message'),
            value : '',
            error : null,
            type : 'textarea',
            options : [
                this.translate.instant("Journée sécurité"),
                this.translate.instant("SafetyBox VR"),
                this.translate.instant("Abonnement"),
                this.translate.instant("Devenir distributeur"),
            ]
        },
        {
            id : 'origin',
            placeholder : this.translate.instant('Comment nous avez-vous connus ?'),
            value : '',
            error : null,
            type : 'select',
            options : []
        }
    ]

    public inputs = []

    public formStatus : 'sending' | 'sended' | 'default' = 'default'

    public showGratitude:boolean = false

    private knowns = []
    private captchaToken = null

    @Input() autoFill = true
    @Input() showSendButton = true
    @Input() removeFields = []
    @Input() addFields = []
    @Input() formId = null
    @Input() prefillFields = null
    @Input() additionalInfos = null

    @Output() formError: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() formSuccess: EventEmitter<Object> = new EventEmitter<Object>();


    public MISSING_PARAMETER_MESSAGE = this.translate.instant("Tout les champs n'ont pas été correctement remplis")
    public BAD_CAPTCHA_MESSAGE = this.translate.instant("Veuillez re-valider le captcha")
    public GENERAL_ERROR = this.translate.instant("Une erreur est survenue, veuillez réessayer plus tard")
    public SUCCESSFUL = this.translate.instant("Votre message a été envoyé. Nous y répondrons dans les plus brefs délais.")

    constructor(
        private translate : TranslateService,
        private registerSettingService : RegisterSettingService,
        private accountService : AccountService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private contactService : ContactService,
        private utils : UtilsService,
        private language: LanguageService
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes){
        this.inputs = this.initialInputs
        if(this.addFields){
            for(let field of this.addFields){
                var toAdd = JSON.parse(JSON.stringify(field))
                toAdd['value'] = ''
                toAdd['error'] = null
                this.inputs.push(toAdd)
            }
        }

        if(this.removeFields){
            this.inputs = this.initialInputs.filter(e => !this.removeFields.includes(e['id']))
        }

        if(this.prefillFields){
            for(let field of this.prefillFields){
                if(field['id'] && this.inputs.find(e => e['id'] == field['id'])){
                    this.inputs.find(e => e['id'] == field['id'])['value'] = field['value']
                }
            }
        }

        this.loadOrigins()

        if(this.autoFill){
            this.autoFillInputs()
        }
    }


    loadOrigins(){
        if(this.inputs.find(e => e['id'] == 'origin')){
            if(!this.knowns.length){
                return this.registerSettingService.getAllRegisterSetting()
                .then((res)=>{
                    var nameAttr = (this.translate.currentLang == 'fr' ? 'name' : 'name_'+this.translate.currentLang)
                    this.knowns = res.filter(e =>e.type == "known").map(e => {return {
                        value : e['id'].toString(),
                        label : e[nameAttr]
                    }})
                    this.inputs.find(e => e['id'] == 'origin')['options'] = this.knowns
                })
            }
            else{
                this.inputs.find(e => e['id'] == 'origin')['options'] = this.knowns
            }
        }
    }

    autoFillInputs(){
        if(this.accountService.isLogged()){
            for(let attr of ['name','mail','phone','company']){
                if(this.inputs.find(e => e['id'] == attr)){
                    this.inputs.find(e => e['id'] == attr)['value'] = this.accountService.userInfos[attr]
                }
            }
        }

    }

    checkFormInputs(){
        for(let input of this.inputs){
            input['error'] = false
            if(input['value'] || input['value'] === '0'){
                if(input['type'] == 'select' && !input['options'].map(e => e['value']).includes(input['value'])){
                    input['error'] = true
                }
                if(input['type'] == 'email' && !this.utils.validMail(input['value'])){
                    input['error'] = true
                }
                // if(input['type'] == 'phone' && !this.utils.validPhone(input['value'])){
                //     input['error'] = true
                // }
            }
            else{
                input['error'] = true
            }

        }
        return !this.inputs.map(e => e['error']).includes(true)
    }



    sendForm(){
        this.testCaptcha()
        if(this.formStatus == 'default' && this.checkFormInputs()){
            this.formStatus = 'sending'
            this.testCaptcha()
            .then((token)=>{
                if(token){
                    this.contactService.postContactForm(this.getInfosToPost())
                    .then((res)=>{
                        if(res && res['status'] && res['status'] == 'error' && res['message'] && res['message'] == 'bad_captcha'){
                            Swal.fire({
                                'text' : this.translate.instant("Veuillez re-valider le captcha"),
                                'icon' : "error",
                                'showCancelButton': false,
                            });
                            this.formError.emit("Veuillez re-valider le captcha")
                            this.formStatus = 'default'
                        }
                        else{
                            this.showGratitude = true
                            if(isBrowser() && window){
                                window['dataLayer'] = window['dataLayer'] || [];
                                window['dataLayer'].push({
                                    'event': 'lead_validation',
                                });
                            }
                            Swal.fire({
                                'html': (this.formId ? '<div id="' + this.formId + 'FormValidation">' : '<div>') + this.translate.instant("Votre message a été envoyé. Nous y répondrons dans les plus brefs délais.") + '</div>',
                                'icon': "success",
                                'showCancelButton': false,
                            })
                            .then(() => {
                                this.formSuccess.emit("Votre message a été envoyé. Nous y répondrons dans les plus brefs délais.")
                                this.formStatus = 'sended'
                                if(localStorage.getItem('last_utm_source_detail') !== null){
                                  localStorage.removeItem('last_utm_source_detail')
                                }
                                if(localStorage.getItem('last_utm_source') !== null){
                                  localStorage.removeItem('last_utm_source')
                                }
                            })
                        }
                    })
                }
                else{
                    Swal.fire({
                        title : this.translate.instant('Erreur'),
                        text : this.translate.instant("Veuillez re-valider le captcha"),
                        icon : 'error'
                    })
                    this.formError.emit("Veuillez re-valider le captcha")
                    this.formStatus = 'default'
                }
            })
        }
    }

    formIsSending(){
        return this.formStatus == 'sending'
    }

    testCaptcha(){
        return new Promise((resolve)=>{
            this.recaptchaV3Service.execute('contactform')
            .subscribe((token) => {
                if(token){
                    this.captchaToken = token
                    resolve(token)
                }
                else{
                    resolve(null)
                }
            });
        })
    }

    getInfosToPost(){
        var toRes:any = {}
        for(let input of this.inputs){
            toRes[input['id']] = input['value']
        }

        if(this.additionalInfos){
            for(let field of this.additionalInfos){
                if(typeof field['id'] != "undefined" && typeof field['value'] != "undefined"){
                    toRes[field['id']] = field['value']
                }
            }
        }

        toRes['captcha'] = this.captchaToken ?? null
        toRes['lang'] = this.language.lang
        toRes['utm_source'] = localStorage.getItem('last_utm_source') || null
        toRes['utm_source_detail'] = localStorage.getItem('last_utm_source_detail') || null
        return toRes
    }

  removeSpace(x, input){
    if (x.keyCode === 32 && input.type == 'email') {
     this.inputs[2].value = this.inputs[2].value.replace(/ /g,'')
    }
  }

}
