import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';




@Injectable({providedIn: 'root'})
export class UnityReportService {

  constructor(private httpClient: HTTPClientService) { }

  getConfigurationByProductId(productId){
    return this.httpClient.get('report/config/'+productId)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getConfigurationsByProductId(productId){
    return this.httpClient.get('report/configs/'+productId)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  newVersion(scenarioId){
    return this.httpClient.post('report/scenario/newVersion',{scenarioId:scenarioId})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  lockScenario(scenarioId){
    return this.httpClient.post('report/scenario/lock',{scenarioId:scenarioId})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  saveScenario(scenario){
    return this.httpClient.post('report/scenario',{"scenario":scenario})
      .then((response: any) => { return response })
      .catch((error)=>{console.error(error)});
  }

  removeScenario(scenarioId){
    return this.httpClient.delete('report/scenario',{"id":scenarioId})
      .then((response: any) => { return response })
      .catch((error)=>{console.error(error)});
  }

  saveObjective(objective){
    return this.httpClient.post('report/objective',{"objective":objective})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  removeObjective(objectiveId){
    return this.httpClient.delete('report/objective',{"id":objectiveId})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  saveRemediation(remediation){
    return this.httpClient.post('report/remediation',{"remediation":remediation})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  removeRemediation(remediationId){
    return this.httpClient.delete('report/remediation',{"id":remediationId})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getReportsByAccountIdAndProductId(productId){
    return this.httpClient.get('unityreport/'+productId)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getSkills(){
    return this.httpClient.get('unityreport/skills')
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getSkillsByObjective(objectiveId){
    return this.httpClient.get('unityreport/skills/'+objectiveId)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  updateSkill(skill){
    return this.httpClient.post('unityreport/skills',{"skill":skill})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  removeSkill(skill){
    return this.httpClient.delete('unityreport/skills',{"skill":skill})
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getSkillConfig(){
    return this.httpClient.get('unityreport/skillconfig/all')
      .then((response: any) => { return response })
      .catch(console.error);
  }

  searchSkillConfig(str){
    return this.httpClient.getUrl()+'unityreport/skillconfig/'+str;
  }

  getSkillConfigByScenario(scenarioId){
    return this.httpClient.get('unityreport/skillconfigbyscenario/'+scenarioId)
      .then((response: any) => { return response })
      .catch(console.error);

  }

  getAverageReports(productId:string,name:string="null",dateBegin:string="null",dateEnd:string="null"){
    return this.httpClient.get('companys/averageReport/'+productId+'/all/'+name+'/'+dateBegin+'/'+dateEnd)
      .then((response: any) => { return response })
      .catch(console.error);
  }

  getAverageReportsWithInferior(productId:string,name:string="null",dateBegin:string="0",dateEnd:string="null"){
      return this.httpClient.get('companys/averageReport/'+productId+'/inferior/'+name+'/'+dateBegin+'/'+dateEnd)
        .then((response: any) => { return response })
        .catch(console.error);
  }

  getAverageReportsWithOrphan(productId:string,name:string="null",dateBegin:string="0",dateEnd:string="null"){
      return this.httpClient.get('companys/averageReport/'+productId+'/orphan/'+name+'/'+dateBegin+'/'+dateEnd)
        .then((response: any) => { return response })
        .catch(console.error);
  }



}
