<form>
    <div class="form-group" *ngFor="let input of inputs">
        <input [disabled]="formStatus != 'default'" *ngIf="['text','email','phone'].includes(input['type'])" type="input['type']" class="form form-control" placeholder="{{input['placeholder']}}" [ngClass]="{'is-invalid':input['error']}" (keyup)="removeSpace($event,input)" [(ngModel)]="input['value']" name="{{input['id']}}"/>
        <select [disabled]="formStatus != 'default'" *ngIf="input['type'] == 'select'" class="custom-select" [ngClass]="{'is-invalid':input['error'], 'empty':!input['value']}" [(ngModel)]="input['value']" name="{{input['id']}}">
            <option disabled value="">{{input['placeholder']}}</option>
            <option *ngFor="let option of input['options']" value="{{option['value']}}">{{option['label']}}</option>
        </select>
        <div class="position-relative" *ngIf="input['type'] == 'textarea'">
            <textarea [disabled]="formStatus != 'default'" placeholder="{{input['placeholder']}}" class="form-control" [ngClass]="{'is-invalid':input['error']}" [(ngModel)]="input['value']" name="{{input['id']}}"></textarea>
            <div class="d-flex flex-wrap align-items-center textoptions" *ngIf="input['options'] && input['options'].length && !input['value']">
                <div class="rounded-full textoption" *ngFor="let option of input['options']" (click)="input['value'] = option">{{option}}</div>
            </div>
        </div>
        <small class="text-danger" *ngIf="input['error']">{{'Le champ ci-dessus est vide ou incorrect' | translate}}</small>
    </div>

    <div class="row d-flex align-items-center">
      <div class="col-12 col-lg-12 col-xl-8 col-md-8 mb-3 mb-lg-3 mb-xl-0 mb-md-0 call-back">
        {{'Nous vous recontactons dans la journée !' | translate}}
      </div>
      <div class="col-12 col-lg-12 col-xl-4 col-md-4 text-right" *ngIf="showSendButton">
          <button class="send {{formStatus}}" (click)="sendForm()" [disabled]="formStatus == 'sending'">
              <span *ngIf="formStatus == 'default'">{{'Envoyer' | translate}}</span>
              <i *ngIf="formStatus == 'default'" class="fas fa-paper-plane"></i>

              <span *ngIf="formStatus == 'sending'">{{'Envoi...' | translate}}</span>
              <i *ngIf="formStatus == 'sending'" class="fas fa-circle-notch fa-spin"></i>

              <span *ngIf="formStatus == 'sended'">{{'Envoyé' | translate}}</span>
              <i *ngIf="formStatus == 'sended'" class="fas fa-check"></i>
          </button>
      </div>
    </div>

</form>

<app-iframe-gratitude *ngIf="showGratitude" iframeurl="/gratitude/contact.html" (iframeLoaded)="showGratitude=false"></app-iframe-gratitude>
