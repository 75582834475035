import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UtilsService } from  '../../services/utils.service'
import { AccountService } from '../../services/account.service';
import { AppService } from '../../services/app.service';
import { LanguageService } from '../../services/language.service';
import { LocalstorageService } from '../../services/localstorage.service'
import { DOCUMENT } from '@angular/common';

import { environment } from '../../../../environments/environment';
import { isBrowser } from '../../thirdparty/isBrowser'
declare var Swal:any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    public user: any = {};

    public showPassword = false

    constructor(
        public router: Router,
        public accountService: AccountService,
        public utils: UtilsService,
        public appService: AppService,
        public activatedRoute: ActivatedRoute,
        public language : LanguageService,
        public localStorage : LocalstorageService,
        @Inject(DOCUMENT) public document: Document
    ) {}

    ngOnInit() {
    }

    login() {

        var redirictURL = null
        var hrefURL = null

        var urlSplitted = this.router.url.substr(1).split('/')
        if(urlSplitted[0] == 'fr'){
            urlSplitted = urlSplitted.slice(1)
        }

        if(urlSplitted[0] == "login" && urlSplitted.length == 2){
            try{
                var options = JSON.parse(atob(urlSplitted[1]))
                if(options['redirictAfterLogin']){
                    redirictURL = options['redirictAfterLogin']
                }
                else if(options['hrefAfterLogin']){
                    hrefURL = options['hrefAfterLogin']
                }
            } catch(err){}
        }

        this.accountService.login(this.user["mail"].trim(), this.user["password"]).then(res => {

            if(isBrowser()) window.scrollTo(0, 0);

            if (res["err"] === 0){
                if(redirictURL){
                    this.router.navigate(this.language.navigateAccordingToLang([redirictURL]));
                }
                else if(hrefURL){
                    if(isBrowser()){
                        var href = options['hrefAfterLogin']
                        if(options['withToken']){
                            var url = new URL(options['hrefAfterLogin'])
                            url.searchParams.append("token", btoa(localStorage.getItem('AuthToken')))
                            href = url.toString()
                        }
                        if(isBrowser()){
                            this.document.location['href'] = href
                        }
                    }
                }
                else if(!this.accountService.catchRedirection){
                    this.document.location['href'] = environment['accountURL']+((this.language.lang && this.language.lang =='fr') ? 'fr/' : '')
                }
                else if(this.accountService.catchRefresh){
                    if(isBrowser()) window.location.reload();
                }

                if(this.accountService.catchRedirection){
                    this.accountService.catchRedirection = false
                }
                if(this.accountService.catchRefresh){
                    this.accountService.catchRefresh = false
                }

                this.appService.hidePopup()
            }
            else{
                if(res['err'] && (res['err'] == 1 || res['err'] == 2)){
                    Swal.fire(this.language.translate.instant('Erreur'), this.language.translate.instant('Email ou mot de passe incorrect'), 'error')
                }
                else if(res['err'] && res['err'] == 3){
                    Swal.fire(this.language.translate.instant('Erreur'), this.language.translate.instant('Ce compte n\'est pas activé, verrifiez vos emails'), 'error')
                }
                else{
                    Swal.fire(this.language.translate.instant('Erreur'), this.language.translate.instant('Une erreur est survenue, veuillez réessayer plus tard'), 'error')
                }
            }


        });
    }

    newPasswd() {
        if(!this.utils.validMail(this.user["mail"])){
            Swal.fire(this.language.translate.instant('Erreur'), this.language.translate.instant('Veuillez saisir une adresse email valide'), 'error')
        }
        else{
            this.accountService.newPasswd(this.user["mail"])
            .then((res) => {
                console.log(res, res['success'])
                if(res && res['success']){
                    Swal.fire('', this.language.translate.instant("Si le compte existe, vous allez recevoir un email"), 'success')
                }
                else{
                    Swal.fire(this.language.translate.instant('Erreur'), this.language.translate.instant('Une erreur est survenue, veuillez réessayer plus tard'), 'error')
                }
            });
        }
    }

    register(){
        if(this.user["mail"]){
            this.localStorage.setItem('preFillEmail', this.user["mail"])
        }
        this.router.navigate(this.language.navigateAccordingToLang(['/register']))
        this.appService.hidePopup()
    }
}
