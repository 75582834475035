import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'contact-us-btn',
  templateUrl: './contact-us-btn.component.html',
  styleUrls: ['./contact-us-btn.component.scss']
})
export class ContactUsBtnComponent implements OnInit {

    @Input() text = this.language.translate.instant('Learn more about how we handle this topic')
    @Input() paddings='p-4'

  constructor(
      public language : LanguageService
  ) { }

  ngOnInit(): void {
  }

}
