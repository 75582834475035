import { NgModule, ModuleWithProviders } from '@angular/core';

//Modules
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { OwlModule } from 'ngx-owl-carousel';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMdModule } from 'ngx-md';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { ChartsModule } from 'ng2-charts';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { MyDatePickerModule } from 'mydatepicker';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl'
import { RecaptchaModule, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { FileUploadModule, FileDropDirective, FileSelectDirective } from 'ng2-file-upload';
import { ClickOutsideModule } from 'ng-click-outside';

//Services
import { AccountService } from './services/account.service'
import { AppService } from './services/app.service'
import { BasketService } from './services/basket.service'
import { CacheService } from './services/cache.service'
import { ClientOrderService } from './services/clientorder.service'
import { ContactService } from './services/contact.service'
import { CustomProductsService } from './services/custom-products.service'
import { DatasService } from './services/datas.service'
import { FaqService } from './services/faq.service'
import { HierarchieService } from './services/hierarchie.service'
import { HTTPClientService } from './services/httpclient.service'
import { HubEventsService } from './services/hub-events.service'
import { HubSpaceService } from './services/hub-spaces.service'
import { InterceptedHttp } from './services/intercepted-http.service'
import { JobsService } from './services/jobs.service'
import { LanguageService } from './services/language.service'
import { LauncherService } from './services/launcher.service'
import { LocalstorageService } from './services/localstorage.service'
import { MetadatasService } from './services/metadatas.service'
import { NewsService } from './services/news.service'
import { NotificationsService } from './services/notifications.service'
import { ProdTagService } from './services/prodtag.service'
import { ProductsService } from './services/products.service'
import { RegisterSettingService } from './services/register-setting.service'
import { ReportService } from './services/report.service'
import { ResellerService } from './services/reseller.service'
import { SubscriptionsService } from './services/subscriptions.service'
import { TheyTrusUsService } from './services/theyTrusUs.service'
import { UnityReportService } from './services/unity-report.service'
import { UtilsService } from './services/utils.service'
import { LikeButtonService} from "./services/like-button.service";


//Pipes
import { ActusPipe } from './pipes/actus.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { UserPipe } from './pipes/user.pipe';

//Other
import { environment } from "../../environments/environment";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//Components
import { ActualiteCardComponent } from './components/actualite-card/actualite-card.component'
import { ContactFindFormationComponent } from './components/contact-find-formation/contact-find-formation.component'
import { CurrencyComponent } from './components/currency/currency.component'
import { FeaturedNewsComponent } from './components/featured-news/featured-news.component'
import { FooterComponent } from './components/footer/footer.component'
import { HeaderComponent } from './components/header/header.component'
import { HeaderPopupComponent } from './components/header-popup/header-popup.component'
import { HierarchyComponent } from './components/hierarchy/hierarchy.component'
import { IframeGratitudeComponent } from './components/iframe-gratitude/iframe-gratitude.component'
import { ImmersiveDropdownComponent } from './components/immersive-dropdown/immersive-dropdown.component'
import { IntlTelInputComponent } from './components/intl-tel-input/intl-tel-input.component'
import { LoginComponent } from './components/login/login.component'
import { NotificationWindowComponent } from './components/notification-window/notification-window.component'
import { PartnersCarouselComponent } from './components/partners-carousel/partners-carousel.component'
import { ProductCardComponent } from './components/product-card/product-card.component'
import { RatingIconsComponent } from './components/rating-icons/rating-icons.component'
import { SvgIconComponent } from './components/svg-icon/svg-icon.component'
import { TheyTrustUsComponent } from './components/they-trust-us/they-trust-us.component'
import { WhyChooseImmersiveComponent } from './components/why-choose-immersive/why-choose-immersive.component'
import { FlagComponent } from './components/flag/flag.component';
import { PasswordCheckerComponent } from './components/password-checker/password-checker.component';
import { CarouselPeopleComponent } from './components/carousel-people/carousel-people.component';
import { SliderComponent } from './components/slider/slider.component';
import { WebglBuildComponent } from './components/webgl-build/webgl-build.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactUsBtnComponent } from './components/contact-us-btn/contact-us-btn.component';
import { CookiesWarningComponent } from './components/cookies-warning/cookies-warning.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { LazyloadComponent } from './lazyload/lazyload.component';
import { LangFlagComponent } from './components/lang-flag/lang-flag.component';
import { TarifsCardComponent } from './components/tarifs-card/tarifs-card.component'
import { VrLogoComponent } from './components/vr-logo/vr-logo.component'

const pipes = [
    ActusPipe,
    KeysPipe,
    UserPipe
]

const modules = [
    CommonModule,
    RouterModule,
    FormsModule,
    OwlModule,
    NgSelectModule,
    ClipboardModule,
    NgxUsefulSwiperModule,
    ChartsModule,
    Ng2OrderModule,
    MyDatePickerModule,
    NgScrollbarModule,
    RecaptchaModule,
    FileUploadModule,
    ClickOutsideModule,
    HttpClientModule,
    RecaptchaV3Module
]

const components = [
    ActualiteCardComponent,
    ContactFindFormationComponent,
    CurrencyComponent,
    FeaturedNewsComponent,
    FooterComponent,
    HeaderComponent,
    HeaderPopupComponent,
    HierarchyComponent,
    IframeGratitudeComponent,
    ImmersiveDropdownComponent,
    IntlTelInputComponent,
    LoginComponent,
    NotificationWindowComponent,
    PartnersCarouselComponent,
    ProductCardComponent,
    RatingIconsComponent,
    SvgIconComponent,
    TheyTrustUsComponent,
    WhyChooseImmersiveComponent,
    FlagComponent,
    PasswordCheckerComponent,
    CarouselPeopleComponent,
    SliderComponent,
    WebglBuildComponent,
    ContactFormComponent,
    ContactUsBtnComponent,
    CookiesWarningComponent,
    TestimonialsComponent,
    LazyloadComponent,
    LangFlagComponent,
    TarifsCardComponent,
    VrLogoComponent
]

@NgModule({
    declarations: [
        ...pipes,
        ...components,
        
    ],
    imports: [
        ...modules,
        NgxMdModule.forRoot(),
        TranslateModule.forChild(),
        NgxMapboxGLModule.withConfig({
            accessToken: 'pk.eyJ1IjoiaW1tZXJzaXZlZmFjdG9yeSIsImEiOiJjam81c2NqOXEwY2hxM3FwcTVrcGUyenpqIn0.gQl6BNut8oX7g7Ucyq2lKw'
        })
    ],
    exports : [
        //Pipes
        ...pipes,
        //Components
        ...components,
        //Modules
        ...modules,
        NgxMdModule,
        TranslateModule,
        NgxMapboxGLModule
    ]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers : [
                AccountService,
                AppService,
                BasketService,
                CacheService,
                ClientOrderService,
                ContactService,
                CustomProductsService,
                DatasService,
                FaqService,
                HierarchieService,
                HTTPClientService,
                HubEventsService,
                HubSpaceService,
                InterceptedHttp,
                JobsService,
                LanguageService,
                LauncherService,
                LocalstorageService,
                MetadatasService,
                NewsService,
                NotificationsService,
                ProdTagService,
                ProductsService,
                RegisterSettingService,
                ReportService,
                ResellerService,
                SubscriptionsService,
                TheyTrusUsService,
                UnityReportService,
                UtilsService,
                LikeButtonService,
                { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lc6WQseAAAAACdGrBZEBXB-2zfNrIASomU1-l2e' }
            ]
        }
    }
}
