import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

const routes: Routes = [
    { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
    { path: '', loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)},
    { path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
