import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({providedIn: 'root'})
export class DatasService {

  constructor(
      private httpClient: HTTPClientService
  ) { }

    getAllDatas(recup){
        return this.httpClient.post('frontdatas', recup)
          .then((response: any) => { return response })
          .catch(console.error);
    }

}
