export const routes = {
	"": {
		en: "",
		fr: null
	},
	"basket": {
		en: "basket",
		fr: null
	},
	"checkout": {
		en: "checkout",
		fr: null
	},
	"safetybox-vr": {
		en: "safetybox-vr",
		fr: null
	},
	"security-welcome": {
		en: "security-welcome",
		fr: "accueil-securite"
	},
	"vr-training-osh": {
		en: "vr-training-osh",
		fr: "formation-vr-sst"
	},
	"safety-day": {
		en: "safety-day",
		fr: "journee-securite"
	},
    "our-contributors": {
		en: "our-contributors",
		fr: "nos-contributeurs"
	},
	"virtual-campus": {
		en: "virtual-campus",
		fr: "campus-virtuel"
	},
	"digital-learning": {
		en: "digital-learning",
		fr: "formation-digitale"
	},
	"safety-quarter-hour": {
		en: "safety-quarter-hour",
		fr: "quart-heure-securite"
	},
	"press-releases": {
		en: "press-releases",
		fr: "communiques-de-presse"
	},
	"events": {
		en: "events",
		fr: "evenements"
	},
	"white-papers": {
		en: "white-papers",
		fr: "livres-blancs"
	},
	"industry-health": {
		en: "industry-health",
		fr: "industrie-sante"
	},
	"industry-waste-management": {
		en: "industry-waste-management",
		fr: "industrie-gestion-dechets"
	},
	"industry-university": {
		en: "industry-university",
		fr: "industrie-universite"
	},
	"industry-food": {
		en: "industry-food",
		fr: "industrie-agroalimentaire"
	},
	"industry-construction": {
		en: "industry-construction",
		fr: "industrie-construction"
	},
	"industry-pharmaceutical": {
		en: "industry-pharmaceutical",
		fr: "industrie-pharmaceutique"
	},
	"industry-road-safety": {
		en: "industry-road-safety",
		fr: "industrie-securite-routiere"
	},
	"industry-manufacturing": {
		en: "industry-manufacturing",
		fr: "industrie-manufacturiere"
	},
	"industry-logistics": {
		en: "industry-logistics",
		fr: "industrie-logistique"
	},
	"industry-petrochemicals": {
		en: "industry-petrochemicals",
		fr: "industrie-petrochimie"
	},
	"jobs": {
		en: "jobs",
		fr: "recrutement"
	},
	"legal": {
		en: "legal",
		fr: null
	},
	"partners": {
		en: "partners",
		fr: "partenaires"
	},
	"research-innovation": {
		en: "research-innovation",
		fr: "recherche-innovation"
	},
	"blog": {
		en: "blog",
		fr: "actualites"
	},
	"faq": {
		en: "faq",
		fr: "faq"
	},
	"content": {
		en: "content",
		fr: null
	},
	"about-us": {
		en: "about-us",
		fr: "a-propos"
	},
	"about-us/o": {
		en: "about-us/o",
		fr: "a-propos/o"
	},
	"subscription": {
		en: "subscription",
		fr: "abonnement"
	},
	"register": {
		en: "register",
		fr: null
	},
	"downloads": {
		en: "downloads",
		fr: null
	},
	"demo": {
		en: "demo",
		fr: null
	}
}
