import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { AccountService } from './../../services/account.service';
import { AppService } from './../../services/app.service';
import { HTTPClientService } from './../../services/httpclient.service'
import { ProductsService } from './../../services/products.service';
import { BasketService } from './../../services/basket.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import {isBrowser} from '../../thirdparty/isBrowser'


@Component({
    selector: 'app-header-popup',
    templateUrl: './header-popup.component.html',
    styleUrls: ['./header-popup.component.css'],
    providers: []
})
export class HeaderPopupComponent implements OnInit {

    public state: {};
    public searchStr = "";

    public telecommandeClose:boolean=false;



    constructor(
        public accountService: AccountService,
        public appService : AppService,
        private router: Router,
        private productsService: ProductsService,
        private httpService:  HTTPClientService,
        public translate: TranslateService,
        public basketService: BasketService,
        public language : LanguageService
    ) {}

    ngOnInit() {
    }

    logout() {

        this.accountService.logout();
    }

    onSelectedItem(e){
        if(e && e.title){
            this.router.navigate(this.language.navigateAccordingToLang(['/content/'+e.title]));
            this.searchStr = "";
            this.appService.popupAccount();
        }
    }

    logOrBasket(){
        if(this.accountService.isLogged()){
            this.router.navigate(this.language.navigateAccordingToLang(['/basket']))
            this.appService.popupAccount();

        }else{
            // this.telecommandeClose = !this.telecommandeClose;
            this.router.navigate(this.language.navigateAccordingToLang(['/basket']))
            this.appService.popupAccount();
        }
    }

    // clickOutside(){
    //     if(isBrowser()){
    //         var selection = window.getSelection();
    //         if(selection.toString().length === 0) {
    //             this.appService.popupAccount()
    //         }
    //     }
    // }

}
