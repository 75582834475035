import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

import { environment } from '../../../environments/environment';


@Injectable({providedIn: 'root'})
export class TheyTrusUsService {
    constructor(private httpClient: HTTPClientService) { }

    getTrustCompanies(type:any = ""): Promise<any> {
      return this.httpClient.get('theyTrustUs/'+type)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getLogoUrl(logo){
        return environment.dataStorage+logo;
    }
}
