import { Injectable } from '@angular/core';
import { AccountService } from './account.service'
import { Router } from '@angular/router';
import { HTTPClientService } from './httpclient.service';
import { LanguageService } from './language.service';


declare var Swal:any;
declare var $:any; import {isBrowser} from '../thirdparty/isBrowser'

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

    public notifsInterval:any = null

    public notifications:any = {
        list : [],
        unread : 0
    }

  constructor(
      public httpClient: HTTPClientService,
      public accountService : AccountService,
      public router : Router,
      public language : LanguageService
  ) {
   }

   startListen(){
       this.getNotifications()

   }




   getNotifications(){
       return new Promise((resolve)=>{
           if(this.accountService.isLogged()){
               this.httpClient.get('notification/get/unify')
               .then((response: any) => { return response })
               .then((notifications)=>{
                   for(let notification of notifications){
                       notification['message'] = {
                           'fr' : notification['message'],
                           'en' : notification['messageEn'],
                           'es' : notification['messageDe'],
                           'it' : notification['messageIt'],
                           'de' : notification['messageEs']
                       }
                       delete notification['messageEn']
                       delete notification['messageDe']
                       delete notification['messageIt']
                       delete notification['messageEs']
                   }
                   this.notifications.list = notifications
                   this.notifications.unread = 0
                   for(let notif of this.notifications.list.filter(notif1 => !notif1.viewed)){
                       if(notif['group']){
                           this.notifications.unread = this.notifications.unread+notif['nbUnread']
                       }
                       else{
                           this.notifications.unread++
                       }
                   }
                   resolve(undefined)
               })
               .catch(console.error);
           }
           else{
               resolve(undefined)
           }
       })
   }



   notifyUser(){

   }

   markAsRead(notif, isGroup=false){
       return new Promise((resolve)=>{
           resolve(undefined)
           var notifId = notif.id
           if(isGroup){notifId = notif.notifType}
           this.httpClient.post('notification/viewed', {notificationId:notifId})
           .then((response: any) => { return response })
           .then(()=>{
               this.getNotifications()
               .then(()=>{
                   resolve(undefined)
               })
           })
           .catch(console.error);
       })

   }

   clickOnNotif(notif){
       if(!notif.viewed){
           this.markAsRead(notif)
           .then(()=>{
               this.router.navigate(this.language.navigateAccordingToLang(['/'+notif.link]))
           })
       }
       else{
           this.router.navigate(this.language.navigateAccordingToLang(['/'+notif.link]))
       }

   }

   markAllAsRead(){
       if(this.notifications.unread){
           this.httpClient.post('notification/viewed/all',{})
           .then((response: any) => { return response })
           .then(()=>{
               this.getNotifications()
           })
           .catch(console.error);
       }
   }
}
