import { Component, OnInit,Input,EventEmitter,Output,OnChanges,SimpleChanges } from '@angular/core';

import { UtilsService } from '../../services/utils.service'
import { BasketService } from '../../services/basket.service'

@Component({
    selector: 'app-currency',
    templateUrl: './currency.component.html',
    styleUrls: ['./currency.component.css']
})
export class CurrencyComponent implements OnInit,OnChanges {

    @Input() currency:string = null;
    @Input() currencyvalue:string = "0";
    @Input() displaysymbol = false;
    @Output("currentSymbolChange") currentSymbolChange:EventEmitter<any> = new EventEmitter<any>();

    public value:string = "0";
    public symbol:string = "€";

    constructor(
        public utilsService : UtilsService,
        public basketService : BasketService
    ) { }

    ngOnInit() {
        if(!this.currency){
            this.currency = this.basketService.currentCurrency
        }
        this._handleValue()
    }

    _handleValue(){
        if(!isNaN(Number(this.currencyvalue))){
            this.value = (parseFloat(this.currencyvalue) * parseFloat(this.basketService.conversionArray[this.currency].euroConversion)).toString()
        }
        this.currentSymbolChange.emit({currency:this.basketService.conversionArray[this.currency],value:this.currencyvalue})
    }

    ngOnChanges(changes: SimpleChanges) {
        this.ngOnInit()
    }

}
