import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { InterceptedHttp } from './intercepted-http.service';
import { LanguageService } from './language.service';
import { LocalstorageService } from './localstorage.service'

import { environment } from '../../../environments/environment';
import {isBrowser} from '../thirdparty/isBrowser'

@Injectable({providedIn: 'root'})
export class HTTPClientService {

    private url = environment.backendURL;
    private token = this.localStorage ? this.localStorage.getItem('AuthToken') : null;


    constructor(
        private http: InterceptedHttp,
        public language: LanguageService,
        public localStorage : LocalstorageService
    ) {}

    addHeaders(headers) {
        headers['Authorization'] = `${"JWT " + this.token}`
        headers['X-Accept-Language'] = this.language['lang'] || 'en'
    }

    getAuthorizationHeader() {
        return `${"JWT " + this.token}`;
    }

    updateToken(token): string {
        this.localStorage.setItem('AuthToken', token);

        this.token = this.localStorage ? this.localStorage.getItem('AuthToken') : null;
        return this.token;
    }

    payload() {
        if(!this.token || this.token === "")
        return null;

        let base64Url = this.token.split('.')[1];
        if (base64Url) {
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            if(isBrowser()){
                return JSON.parse(window.atob(base64));
            }
            else{
                return null;
            }

        } else {
            this.updateToken("");

            if(isBrowser()) setTimeout(()=>{
                location.reload();
            }, 300)
        }
    }

    get(path, textReponse=false) {
        const headers = {};
        this.addHeaders(headers)
        return this.http.get(path, {
            headers: headers,
            responseType : ((textReponse) ? 'text' : 'json')
        });

    }

    post(path, data, textReponse=false) {
        const headers = {};
        this.addHeaders(headers);
        return this.http.post(path, data, {
            headers: headers,
            responseType : ((textReponse) ? 'text' : 'json')
        });
    }

    put(path, data) {
        const headers = {};
        this.addHeaders(headers);
        return this.http.put(path, data, {
            headers: headers
        });
    }

    delete(path, body={}) {
        const headers = {};
        this.addHeaders(headers);
        return this.http.delete(path, {
            headers: headers,
            body: body
        });
    }

    getUrl() {
        return this.url;
    }

    getFct(url){
        return this.get(url)
        .then((response: any) => { return response })
        .catch(console.error);
    }

}
