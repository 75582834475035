import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class JobsService {

  constructor(
      public httpClient : HTTPClientService
  ) { }

    getAllJobs(){
        return this.httpClient.get('jobs/all')
          .then((response: any) => { return response })
          .catch(console.error);
      }

    getJobs(){
        return this.httpClient.get('jobs/')
          .then((response: any) => { return response })
          .catch(console.error);
      }

    applyJob(jobId, inputs){
        return this.httpClient.post('job/apply/'+jobId, {candidature:inputs})
          .then((response: any) => { return response })
          .catch(console.error);
    }


}
