import { Injectable } from '@angular/core';
declare var $:any; import {isBrowser} from '../thirdparty/isBrowser'
declare var Fingerprint2:any;
declare var window:any;

@Injectable({providedIn: 'root'})
export class UtilsService {

    constructor() { }

    private globalVars = {};

    numberFormat(_number, _sep=" ") {
        //check if number is present
        var SEPARATOR = ".";
        _number = typeof _number != "undefined" && _number > 0 ? _number + "" : "0";

        //We want to add space, so we get only number before comma
        var real;
        var float;
        if(_number.indexOf(SEPARATOR)!=-1){
            real = _number.substr(0,_number.indexOf(SEPARATOR));
            float = _number;
            float = ","+_number.substr(float.indexOf(SEPARATOR)+1);
        }else{
            real = _number;
            float = "";
        }

        //We apply regex
        real = real.replace(new RegExp("^(\\d{" + (real.length%3? real.length%3:0) + "})(\\d{3})", "g"), "$1 $2").replace(/(\d{3})+?/gi, "$1 ").trim();
        if(typeof _sep != "undefined" && _sep != " ") {
            real = real.replace(/\s/g, _sep);
        }

        //We return price
        if(float == 0 || float == ",0"){
            float = "";
        }else if(float.toString().length == 2){
            float += "0"
        }

        return real+float;
    }

    roundPrices(number, afterV=2) {
        return Math.round(number * Math.pow(10, afterV)) / Math.pow(10, afterV);
    }

    changeBodyClass(clazz){
        if(isBrowser()) $(document.body).removeClass();
        if(isBrowser()) $(document.body).addClass(clazz);
    }

    validPhone(str){
        var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return re.test(String(str).toLowerCase());
    }

    validMail(str){
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(str).toLowerCase());
    }

    isEmpty(str=""){
        if(str==null || str == undefined){ return true }
        else{
            str = str.toString()
            str = str.replace(/[\s]{2,}/g," "); // Enlève les espaces doubles, triples, etc.
            str = str.replace(/^[\s]/, ""); // Enlève les espaces au début
            str = str.replace(/[\s]$/,""); // Enlève les espaces à la fin
            return str == ""
        }
    }

    openLink(link, newTab = true){
        if(isBrowser()){
            if(newTab){

                var win = window.open(link, '_blank');
                win.focus();
            }
            else{
                document.location.href=link
            }
        }
    }

    getDeviceId(){
        return new Promise((resolve,reject)=>{
            if(isBrowser()){
                if (window["requestIdleCallback"]) {
                    window["requestIdleCallback"](function () {
                        Fingerprint2.get(function (components) {
                            var values = components.map(function (component) { return component.value })
                            var murmur = Fingerprint2.x64hash128(values.join(''), 31)
                            resolve(murmur);
                        })
                    })
                } else {
                    setTimeout(function () {
                        Fingerprint2.get(function (components) {
                            var values = components.map(function (component) { return component.value })
                            var murmur = Fingerprint2.x64hash128(values.join(''), 31)
                            resolve(murmur);
                        })
                    }, 500)
                }
            }
            else{
                resolve(undefined)
            }

        })
    }

    formateFileSize(size, floor=false){
        var futureSize = size+0
        var i = Math.floor( Math.log(futureSize) / Math.log(1024))
        if(i<0){
            return  "0 octets"
        }
        else{
            for(var y=0;y<i;y++){
                futureSize = futureSize/1024
            }
            if(floor){
                futureSize = Math.floor(futureSize * Math.pow(10, 2)) / Math.pow(10, 2);
            }
            else{
                futureSize = Math.round(futureSize * Math.pow(10, 2)) / Math.pow(10, 2);
            }
            return futureSize+" "+["Octets", "Ko", "Mo", "Go", "To"][i]
        }
    }

    formatPrice(toChange){
        var price:any = this.roundPrices(toChange,2)
        if(!price)return 0;
        var dec = price.toString().split(".")[1] || "00";
        dec = dec.substr(0,2);
        if(price.toString().indexOf(".") != -1){
            price = price.toString().substr(0,price.toString().indexOf("."))
        }
        var withPoint = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        // withPoint = parseInt(withPoint,10)
        return withPoint.toString()+","+dec;
    }

}
