import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';
import {TranslateService} from '@ngx-translate/core';

declare var Swal:any

@Injectable({providedIn: 'root'})
export class HubSpaceService {

    constructor(
        private httpClient: HTTPClientService,
        public translate : TranslateService
    ) { }

    getAccountSpaces() {
        return this.httpClient.get('hub/account/spaces')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    postSpace(space) {
        return this.httpClient.post('hub/space', space)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    changeAutoRenew(spaceId,newValue){
        return this.httpClient.post('hub/space/autorenew/', {spaceId:spaceId, newValue:newValue})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    //Videos

    getSpaceVideos(spaceId) {
        return this.httpClient.get('hub/room/videos/'+spaceId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    deleteVideo(videoId) {
        return this.httpClient.delete('hub/space/videos/'+videoId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    addVideo(videoInfos) {
        return this.httpClient.post('hub/space/videos/', videoInfos)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    editVideo(videoInfos) {
        return this.httpClient.post('hub/space/videos/', videoInfos)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getYTInfos(ytId){
        return this.httpClient.get('spaces/videos/yt-infos/'+ytId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    //Exercices

    getSpaceExercicess(spaceId) {
        return this.httpClient.get('hub/space/products/'+spaceId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    changeSpaceExercicess(spaceId, productIds) {
        return this.httpClient.post('hub/space/products/'+spaceId, productIds)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    changeSpaceProductLimit(limitInfos) {
        return this.httpClient.post('hub/space/product/limit/',limitInfos)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    deleteExercices(exerciceId) {
        return this.httpClient.delete('hub/space/exercices/'+exerciceId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    postExternalExercice(spaceId, infos) {
        return this.httpClient.post('hub/space/products/custom/'+spaceId, infos)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    deleteSpaceProduct(productId){
        return this.httpClient.delete('hub/space/products/custom/'+productId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    cancelDeletion(productId){
        return this.httpClient.delete('hub/space/products/custom/deletion/'+productId)
        .then((response: any) => { return response })
        .catch(console.error);
    }


    //Users

    changeUserRole(accountId, spaceId, newRole){
        return this.httpClient.post('hub/space/users/', {accountId:accountId, spaceId:spaceId, newRole:newRole})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getSpaceUsers(spaceId){
        return this.httpClient.get('hub/space/users/'+spaceId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    //Stats

    getStats(spaceId, fromWhen, period){
        return this.httpClient.get('space/statistics/'+spaceId+"?period="+fromWhen+"&groupBy="+period)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    refreshExports(spaceId){
        return this.httpClient.get('space/statistics/exports/'+spaceId+'?'+new Date().getTime())
        .then((response: any) => { return response })
        .catch(console.error);
    }

    askAnExport(spaceId){
        return this.httpClient.post('space/statistics/exports', {spaceId:spaceId})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    //swals
    successSwal(full=false){
        if(full){
            return Swal.fire('',this.translate.instant('Les modifications ont bien été enregistrées'), 'success')
        }
        else{
            return Swal.fire({
                toast:true,
                html:this.translate.instant('Les modifications ont bien été enregistrées'),
                icon :'success',
                position:'bottom-right',
                timer : 3000
            })
        }

    }

    errorSwal(full=false){
        if(full){
            return Swal.fire('',this.translate.instant('Une erreur est survenue, veuillez réessayer plus tard'), 'error')
        }
        else{
            return Swal.fire({
                toast:true,
                html:this.translate.instant('Une erreur est survenue, veuillez réessayer plus tard'),
                icon :'error',
                position:'bottom-right',
                timer : 3000
            })
        }
    }

}
