<div class="exercice px-0">
    <a class="img-container mb-2" [routerLink]="language.navigateAccordingToLang([product.link])" title="{{product.name}}">
        <div class="unavailable d-flex align-items-center justify-content-center cursor-pointer" *ngIf="product.level != 'Disponible'">{{"Prochainement" | translate}}</div>
        <div [ngStyle]="{'background-image':'url('+product.preview+')'}" id="{{this.__getContId()}}" (click)="resetPreview()" (mouseenter)="gifPreview()" (mouseleave)="resetPreview()" class="product-preview"></div>
    </a>

    <!-- <ul class="icons d-flex mb-2">
        <li class="custom-logo" *ngIf="productTags['VrAutonome']" title="{{product['textType']}}"><app-svg-icon [src]="'assets/svg/VR_Casque_2021.svg'" [size]="'30px'"></app-svg-icon><small style="font-weight: 600;">{{product['textType']}}</small></li>
        <li class="custom-logo" *ngIf="productTags['VrPc']" title="{{product['textType']}}"><app-svg-icon [src]="'assets/svg/user-helmet-safety-regular.svg'" [size]="'25px'"></app-svg-icon><small style="font-weight: 600;">{{product['textType']}}</small></li>
        <li *ngIf="productTags['Pc']" title="PC"><app-svg-icon [src]="'assets/svg/desktop-solid.svg'" [size]="'18px'"></app-svg-icon></li>
        <li *ngIf="productTags['Webgl']" title="WEBGL"><app-svg-icon [src]="'assets/svg/laptop-solid.svg'" [size]="'18px'"></app-svg-icon></li>
        <li *ngIf="productTags['Multiplayer']" title="Multiplayer"><app-svg-icon [src]="'assets/svg/social.svg'" [size]="'18px'"></app-svg-icon></li>
        <li *ngIf="productTags['Formation']" title="Formation"><app-svg-icon [src]="'assets/svg/graduation-cap-solid.svg'" [size]="'25px'"></app-svg-icon></li>

    </ul> -->
    <span class="titre-exercice" style="gap:5px">
        <app-svg-icon class="text-immersive" [src]="'assets/svg/VR_Casque_2021.svg'" [size]="'30px'" *ngIf="types.includes('VR')"></app-svg-icon>
        <app-svg-icon class="text-immersive" [src]="'assets/svg/desktop-solid.svg'" [size]="'18px'" *ngIf="types.includes('PC')"></app-svg-icon>
        <span>{{ product.name }}</span>
    </span>

    <p class="date-dispo" *ngIf="product.level != 'Disponible'">
        <span *ngIf="product.level != 'En projet' && product.level != 'Bientôt disponible' ">
            {{'Disponible en' | translate}}
            {{months[product.level.slice(0,2)]}}
            {{product.level.slice(-4)}}
        </span>
        <span *ngIf="product.level == 'En projet' || product.level == 'Bientôt disponible'">
            {{ product.level | translate }}
        </span>
    </p>
</div>
