<div class="webgl-container" [ngStyle]="{'background-image' : 'url('+overlayImg+')'}">

    <div id="overlay-texts" class="step-{{step}}">
        <div *ngIf="step == 'overlay'" class="overlay-text cursor-pointer" (click)="playGame()">
            <div [innerHTML]="overlayText" *ngIf="overlayText"></div>
            <div class="play-icon">
                <app-svg-icon class="play-regular" [src]="'assets/svg/play-regular.svg'" [size]="'35px'"></app-svg-icon>
            </div>
        </div>
        <div class="overlay-text" [innerHTML]="loadingText" *ngIf="loadingText && step == 'loading'"></div>
    </div>

    <div id="iframe-container" class="{{!['overlay'].includes(step) ? 'd-block' : 'd-none'}}"></div>
    <div class="invisible-overlay" (click)="clickOnApp()">

    </div>
</div>
