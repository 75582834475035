import { Component, OnInit, Input, Output, EventEmitter,ElementRef,ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

declare var $:any; import {isBrowser} from '../../thirdparty/isBrowser'

@Component({
  selector: 'app-iframe-gratitude',
  templateUrl: './iframe-gratitude.component.html',
  styleUrls: ['./iframe-gratitude.component.css']
})
export class IframeGratitudeComponent implements OnInit {

  private TIME_WAIT_BEFORE_DESTROY:number =10000;


  @Input() iframeurl:any = "";
  @Output() iframeLoaded = new EventEmitter<string>();

  @ViewChild("iframeElement", {static: false}) iframeElement: ElementRef;

  public sanitizeUrl:any = "";

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.sanitizeUrl = this.getIframeUrl();
  }

  ngAfterViewInit(){


  }

  getIframeUrl(){
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.iframeurl);
  }

  iframeLoadedEvent(){
    if(isBrowser()) setTimeout(() => { this.iframeLoaded.emit()},this.TIME_WAIT_BEFORE_DESTROY);
  }

}
