import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';
import {TranslateService} from '@ngx-translate/core';

declare var Swal:any

@Injectable({providedIn: 'root'})
export class HubEventsService {

    constructor(
        private httpClient: HTTPClientService,
        public translate : TranslateService
    ) { }

    getAccountEvents() {
        return this.httpClient.get('hub/account/event')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getAccountEventsForDate(date){
        var fDate = new Date(date).getFullYear()+"-"+('0'+(new Date(date).getMonth()+1)).slice(-2)+"-"+('0'+new Date(date).getDate()).slice(-2)
        return this.httpClient.get('hub/account/events/per-date/'+fDate)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getAccountEventsDates(){
        return this.httpClient.get('hub/account/events/only-dates')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    sendEvent(eventInfos){
        return this.httpClient.post('hub/account/event', eventInfos)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    deleteEvent(eventId){
        return this.httpClient.delete('hub/events/remove/'+eventId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    changePresence(presence, eventId){
        return this.httpClient.post('hub/events/sendPresence', {present:presence, eventId:eventId})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    //swals
    successSwal(full=false){
        if(full){
            Swal.fire('',this.translate.instant('Les modifications ont bien été enregistrées'), 'success')
        }
        else{
            Swal.fire({
                toast:true,
                html:this.translate.instant('Les modifications ont bien été enregistrées'),
                icon :'success',
                position:'bottom-right',
                timer : 3000
            })
        }

    }

    errorSwal(full=false){
        if(full){
            Swal.fire('',this.translate.instant('Une erreur est survenue, veuillez réessayer plus tard'), 'error')
        }
        else{
            Swal.fire({
                toast:true,
                html:this.translate.instant('Une erreur est survenue, veuillez réessayer plus tard'),
                icon :'error',
                position:'bottom-right',
                timer : 3000
            })
        }

    }

}
