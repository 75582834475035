<div class="post rounded mb-4" *ngIf="post && !featuredPost">
    <a *ngIf="!post.ytId" [routerLink]="language.navigateAccordingToLang([post.link])" [ngStyle]="{'background-image' : 'url('+post['img']+')'}" class="imgHeader px-1 mb-3" title="{{post.title}}"></a>
    <a *ngIf="post.ytId" [routerLink]="language.navigateAccordingToLang([post.link])" [ngStyle]="{'background-image' : 'url(https://img.youtube.com/vi/'+post.ytId+'/maxresdefault.jpg)'}" class="imgHeader px-1 mb-3" title="{{post.title}}"></a>
    <div class="informations mb-3 px-1">
        <div class="tag rounded" *ngIf="!isRd">{{post.tag['title']}}</div>
        <span class="date px-2">{{language.getDate(post.createdDate)}}</span>
    </div>
    <div class="actu-body px-1">
        <a class="title mb-3" [routerLink]="language.navigateAccordingToLang([post.link])" title="{{post.title}}">{{post.title}}</a>
        <p class="description mb-2" [innerHTML]="post['shortDesc']"></p>
        <a [routerLink]="language.navigateAccordingToLang([post.link])" class="viewmore p-2 mx-3 mb-3" title="{{post.title}}">{{'Voir plus' | translate}}</a>
    </div>
</div>

<div class="post rounded" *ngIf="post && featuredPost">
  <div class="container mx-0 px-0">
    <div class="row">
    <div class="col-md-6 col-12">
      <a *ngIf="!post.ytId" [routerLink]="language.navigateAccordingToLang([post.link])" [ngStyle]="{'background-image' : 'url('+post['img']+')'}" class="imgHeader px-md-0 mb-md-0 px-1 mb-3" title="{{post.title}}"></a>
      <a *ngIf="post.ytId" [routerLink]="language.navigateAccordingToLang([post.link])" [ngStyle]="{'background-image' : 'url(https://img.youtube.com/vi/'+post.ytId+'/maxresdefault.jpg)'}" class="imgHeader px-md-0 mb-md-0 px-1 mb-3" title="{{post.title}}"></a>
    </div>
    <div class="col-md-6 col-12">
      <div class="informations mb-3">
          <div class="tag rounded" *ngIf="!isRd">{{post.tag['title']}}</div>
          <span class="date px-2">{{language.getDate(post.createdDate)}}</span>
      </div>
      <div class="actu-body">
          <a class="title mb-3" [routerLink]="language.navigateAccordingToLang([post.link])" title="{{post.title}}">{{post.title}}</a>
          <p class="featureDescription mb-5" [innerHTML]="post['longDescription'] || '...'"></p>
          <a [routerLink]="language.navigateAccordingToLang([post.link])" class="viewmore p-2 mx-3 width-50" title="{{post.title}}">{{'Voir plus' | translate}}</a>
      </div>
    </div>
    </div>
  </div>
</div>
