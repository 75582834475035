import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';

import { BasketService } from './../../services/basket.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { FaqService } from '../../services/faq.service';

declare var window:any;
declare var Swal:any;
declare var $:any; import {isBrowser} from '../../thirdparty/isBrowser'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    public technicalSupportOpen = false;

    public chatonline = false;

    private currentParams:any = null;

    public faq = []

    public pageIsNotAboutUs = null;

  constructor(
      public faqService: FaqService,
      private router: Router,
      public basketService: BasketService,
      public translate: TranslateService,
      public language : LanguageService,

  ){}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pageIsNotAboutUs = event.url !== '/about-us' && event.url !== '/fr/a-propos' && event.url !== '/fr/a-propos/o' && event.url !== '/about-us/o';
      }
    })
      this.refreshFaq();
  }

  refreshFaq(){
      this.faqService.getTop5()
      .then((res)=>{
          this.faq = res;
      })
  }

  handleChat(){
    if(isBrowser()){

    }
  }


}
