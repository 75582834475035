import { Component, OnInit, Input, Output } from '@angular/core';

import { LanguageService } from '../../services/language.service';
import {TranslateService} from '@ngx-translate/core';

declare var $:any; import {isBrowser} from '../../thirdparty/isBrowser'
import { ProductsService } from '../../services/products.service';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {

    @Input("product")product:any = null;
    @Input("boxShadowColor")boxShadowColor = '#ffffff'

    public months = {
        "00" : "",
        "01" : this.translate.instant("Janvier"),
        "02" : this.translate.instant("Février"),
        "03" : this.translate.instant("Mars"),
        "04" : this.translate.instant("Avril"),
        "05" : this.translate.instant("Mai"),
        "06" : this.translate.instant("Juin"),
        "07" : this.translate.instant("Juillet"),
        "08" : this.translate.instant("Aout"),
        "09" : this.translate.instant("Septembre"),
        "10" : this.translate.instant("Octobre"),
        "11" : this.translate.instant("Novembre"),
        "12" : this.translate.instant("Décembre"),
    }

    public dispos = [
        this.translate.instant('Disponible'),
        this.translate.instant('En projet'),
        this.translate.instant('Bientôt disponible'),
        this.translate.instant('Obsolète'),
    ]

    public productTags = {};

    constructor(
        public translate : TranslateService,
        public language : LanguageService,
        public productService : ProductsService
    ){}

    isDto = true

    types = []

    ngOnInit() {
        this.resetPreview()
        this.checkTag(this.product);
        if(this.product['name_en'] || this.product['name_en'] === null){
            this.isDto = false
            this.product.name = this.product[(this.language.lang == 'fr' ? 'name' : 'name_'+this.language.lang)]
        }

        if(this.product.isGroup){
            if(this.product.types.length){
                for(let w of this.product.types){
                    if(['PCVR','FOCUSPLUS','PICONEO3','QUEST2','FOCUS3'].includes(w)){
                        this.types.push('VR')
                    }
                    if(['WEBGL','PCNOVR', 'FORMATION'].includes(w)){
                        this.types.push('PC')
                    }
                }
            }
        }
        else{
            if(['PCVR','FOCUSPLUS','PICONEO3','QUEST2','FOCUS3'].includes(this.product.type)){
                this.types.push('VR')
            }
            if(['WEBGL','PCNOVR', 'FORMATION'].includes(this.product.type)){
                this.types.push('PC')
            }
        }

        if(!this.types.length){
            if(this.product.prodtags.find(e => e.id == 253)){
                this.types.push('VR')
            }
            if(this.product.prodtags.find(e => e.id == 254)){
                this.types.push('PC')
            }
        }

        this.types = [...new Set(this.types)]

    }


    gifPreview(){
        if(this.product){
            if(isBrowser()) this.product['timeoutGIF'] = setTimeout(() => {
                var previews = [
                    this.product['preview'],
                    this.product['screenshot1'],
                    this.product['screenshot2'],
                    this.product['screenshot3'],
                ]
                var img= null
                if(isBrowser()) img = $(this.__getContId())
                if(isBrowser()) $(this.__getContId()).css('background-image','url('+this.product.preview+')')
                var i=0;
                if(isBrowser()) this.product['PreviewGIF'] = setInterval(() => {
                    if(i==3){i=0}
                    else{i++}
                    if(isBrowser()) $(this.__getContId()).css('background-image','url('+previews[i]+')')
                }, 750);
            },500)
        }

    }

    resetPreview(){
        if(isBrowser()) clearTimeout(this.product['timeoutGIF']);
        if(isBrowser()) clearInterval(this.product['PreviewGIF'])
        if(isBrowser()) $(this.__getContId()).css('background-image','url('+this.product.preview+')')
    }

    checkTag(product){
        this.productTags = {}
        this.productTags['VrAutonome'] = product.prodtags.map(e => e['id']).includes(1) || product.prodtags.map(e => e['id']).includes(2) || product.prodtags.map(e => e['id']).includes(222) || product.prodtags.map(e => e['id']).includes(247)
        this.productTags['VrPc'] = product.prodtags.map(e => e['id']).includes(221)
        this.productTags['Pc'] = product.prodtags.map(e => e['id']).includes(200)
        this.productTags['Webgl'] = product.prodtags.map(e => e['id']).includes(201)
        this.productTags['Tablette'] = product.prodtags.map(e => e['id']).includes(202)
        this.productTags['Smartphone'] = product.prodtags.map(e => e['id']).includes(203)
        this.productTags['Multiplayer'] = product.prodtags.map(e => e['id']).includes(204)
        this.productTags['Formation'] = product.prodtags.map(e => e['id']).includes(251)
    }

    __getContId(){
        return '#productPreview'+(this.product.isGroup ? +'g':'')+this.product.id
    }

}
