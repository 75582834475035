<div class="reassurance">
    <div class="container mb-4">
        <h3 class="my-4" *ngIf="title">{{ title}}</h3>
        <owl-carousel
            [items]="images"
            [options]="{
                items: 5,
                dots: false,
                nav: true,
                margin:0,
                loop:true,
                autoplay:true,
                autoplayTimeout:3000,
                autoplayHoverPause:true,
                responsive:{
                    '300':{
                        items:1,
                        nav:true
                    },
                    '768':{
                        items:3,
                        nav:true
                    },
                    '992':{
                        items:5,
                        nav:true,
                        loop:true
                    }
                }
            }"
            [carouselClasses]="['owl-carousel']">
            <div class="item" *ngFor="let image of images;let i = index">
                <img alt="Logo of the {{image.name}} company" title="{{image.name}}" [attr.src]="image.url" loading="lazy"/>
            </div>
        </owl-carousel>
    </div>
</div>
