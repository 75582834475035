import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';


@Injectable({providedIn: 'root'})
export class LikeButtonService {

  constructor(
      public httpClient : HTTPClientService
  ) { }

    getLikeNumber(btnName){
      return this.httpClient.get('likes/'+btnName)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    toggleLike(btnName){
        return this.httpClient.post('likes/'+btnName, {})
          .then((response: any) => { return response })
          .catch(console.error);
    }

}
