export const environment = {
    production: true,
    backendURL: "https://immersivefactory.com/backend/",
    dataStorage : "https://data.immfactory.com/",
    stripeApiKey : "pk_live_aJusGzLGLEUsZ9lajOsqKdvT001WhCrWfL",
    ssrFrontURL: "127.0.0.1:2000",
    localbackend : "https://immersivefactory.com/backend/",
    langsURL : "https://data.immfactory.com/langs/",
    websiteURL : "https://immersivefactory.com/",
    accountURL : "https://immersivefactory.com/my-account/"
};
