import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({
    providedIn: 'root'
})
export class LauncherService {

    public pcLink = ""
    public androidLink = ""

    public pcNotice = "https://immersivemisc.s3.eu-west-3.amazonaws.com/launcher-notices/InstallationGuide_VivePro_ImmersiveFactory.pdf"
    public androidNotice = "https://immersivemisc.s3.eu-west-3.amazonaws.com/launcher-notices/InstallationGuide_FocusPlus_ImmersiveFactory.pdf"

    constructor(
        public httpClient : HTTPClientService
    ) { }

    getAccountDownloads(){
        return this.httpClient.get('account/downloads')
        .then((response: any) => { return response })
        .catch(console.error);
    }

}
