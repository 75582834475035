import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';
import {Observable} from 'rxjs';

import { environment } from '../../../environments/environment';
import {isBrowser} from '../thirdparty/isBrowser'


@Injectable({providedIn: 'root'})
export class ProductsService {

    private searchTimer = null;

    public types = [
        { id: "PCVR", name: "VR PC", color: "#0284c7" },
        { id: "FOCUSPLUS", name: "HTC Vive Focus Plus", color: "#0BA875" },
        { id: "WEBGL", name: "Navigateur", color: "#eab308" },
        { id: "PCNOVR", name: "PC", color: "#475569" },
        // { id: "PICONEO2", name: "PICO Neo 2", color: "#9333ea" },
        { id: "PICONEO3", name: "PICO Neo 3 Pro", color: "#6d28d9" },
        { id: "QUEST2", name: "META Quest 2", color: "#0668E1" },
        { id: "FOCUS3", name: "HTC Vive Focus 3", color: "#e11d48" },
        { id: "FORMATION", name : "Formation", color : ""}
    ]

    constructor(
        private httpClient: HTTPClientService,
    ) {
        this.searchTimer = null;
    }

    getProducts(offset=0, limit=12, type="any", available=0): Promise<any> {
        return this.httpClient.get('products/'+offset+'/'+limit+'/'+type+'/'+(available?1:0))
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getPrivateProducts(accountId=null,offset=0, limit=12): Promise<any> {
        return this.httpClient.get('private/products/'+accountId+'/'+offset+'/'+limit)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getProduct(name): Promise<any> {
        return this.httpClient.get('product/name/' + name)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getProductPrices(productId,number): Promise<any> {
        return this.httpClient.get('basket/tarifs/' + productId+"/"+number)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getProductById(id, isGroup=true): Promise<any> {
        return this.httpClient.get((isGroup ? 'product-group/' : 'product/') + id )
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getProductsByIds(ids): Promise<any> {
        return this.httpClient.get('list/products/' + ids)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getProductImage(name, isGroup=false): string {
        return environment.dataStorage + "products/" + (isGroup ? 'groups/' : '') + name;
    }

    getpartnerLogo(name): string {

        return environment.dataStorage + name.substr(1);
    }

    getSearchUrl(name) : string{
        return this.httpClient.getUrl()+"search/product/"+name;
    }

    createTimeoutPromise(time){
        return new Promise((resolve,reject)=>{
            if(isBrowser()) this.searchTimer = setTimeout(() => {
                this.searchTimer = null;
                resolve(undefined)
            },time)
        })
    }

    getHTML(file){
        if(file){
            file = file.substring(1,file.length);
            return this.httpClient.get(environment.dataStorage+file, true)
            .then((response: any) => { return response })
            .catch(console.error);
        }else{
            return Promise.resolve("")
        }

    }

    searchProduct(name, timeout=500): Promise<any> {
        if(this.searchTimer){
            if(isBrowser()) clearTimeout(this.searchTimer)
            this.searchTimer = null;
        }

        return this.createTimeoutPromise(timeout)
        .then(()=>{
            return this.httpClient.get("search/product/"+name)
            .then((response: any) => { return response })
            .catch(console.error);
        })
    }

    getBestSellers(): Promise<any> {
        return this.httpClient.get('products/best_orders')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getLatestUpdates(): Promise<any> {
        return this.httpClient.get('products/featured')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getProductLink(idProduct, nameProduct, link=null){

        if(idProduct !> 0) {

            var productId

            link = idProduct+"-"+nameProduct
            link = link.replace(/[^a-zA-Z0-9-_çïëüâêîôûèàùé ]/g, '')
            link = link.replace(/[\s]{2,}/g," "); // Enlève les espaces doubles, triples, etc.
            link = link.replace(/^[\s]/, ""); // Enlève les espaces au début
            link = link.replace(/[\s]$/,""); // Enlève les espaces à la fin
            link = link.replace(/ /g, '-')
            link = link.replace(/[`~!@#$%^&*()|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
            link = '/content/'+link
            return link;

        } else { return "/content"; }

    }

    setProductsInfos(productList, idProductCat=-1, idPlatformCat=-1){
        for(let product of productList){
            if(product['prodtags']){
              for(let prodtags of product['prodtags']){
                  if(prodtags.catId == idProductCat){
                      product["categorie"] = prodtags.name;
                      product['categorieId'] = prodtags.id;
                  }
                  if(prodtags.catId == idPlatformCat){
                      product["tagPlatform"] = prodtags.name;
                      product['tagPlatformId'] = prodtags.id;
                  }
              }
            }
            if(!product['preview'].includes('https://') && !product['preview'].includes('http://')){
                product['preview'] = this.getProductImage(product['preview'], product['isGroup']);
            }
            if(!product['screenshot1'].includes('https://') && !product['screenshot1'].includes('http://')){
                product['screenshot1'] = this.getProductImage(product['screenshot1'], product['isGroup'])
            }
            if(!product['screenshot2'].includes('https://') && !product['screenshot2'].includes('http://')){
                product['screenshot2'] = this.getProductImage(product['screenshot2'], product['isGroup'])
            }
            if(!product['screenshot3'].includes('https://') && !product['screenshot3'].includes('http://')){
                product['screenshot3'] = this.getProductImage(product['screenshot3'], product['isGroup'])
            }

            product['link'] = (product['isGroup'] ? "g" : "")+product["id"]+"-"+product["name"]
            product['link'] = product['link'].replace(/[^a-zA-Z0-9-_çïëüâêîôûèàùé ]/g, '')
            product['link'] = product['link'].replace(/[\s]{2,}/g," "); // Enlève les espaces doubles, triples, etc.
            product['link'] = product['link'].replace(/^[\s]/, ""); // Enlève les espaces au début
            product['link'] = product['link'].replace(/[\s]$/,""); // Enlève les espaces à la fin
            product['link'] = product['link'].replace(/ /g, '-')
            product['link'] = product['link'].replace(/[`~!@#$%^&*()|+\=?;:'",.<>\{\}\[\]\\\/]/gi, ''); //remove special characters (like /)
            product["link"] = '/content/'+product["link"]

        }
        return productList;
    }

    getTarifs(){
        return this.httpClient.get('basket/tarifs')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    testPartnerLogo(name){
        name = name.substr(1)
        return this.httpClient.get(environment.dataStorage+name)
        .then((response: any) => true)
        .catch((response: any) => false);
    }


    getInterestedUsers(productId){
        return this.httpClient.get('interestingProducts/'+productId)
        .then((response: any) => { return response })
        .catch(console.error);
    }
    getTop5Footer(): Promise<any> {
        return this.httpClient.get('productss/top5footer')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getInterestingProducts(){
        return this.httpClient.get('interestingProducts')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getAllProducts() {
        return this.httpClient.get('admin/products')
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getMyFreeUses(){
        return this.httpClient.post('freeUses/ask',{})
        .then((response: any) => { return response })
        .catch(console.error);
    }

    getOnlySearchUrl(){
        return this.httpClient.getUrl()+"search/product/%str%";
    }

    getPdfUrl(productId,lang){
        return this.httpClient.getUrl()+"product/getpdf/"+productId+"/"+lang;
    }

    getAuthsByProductId(productId){
        return this.httpClient.get('unityauth/myauths/'+productId)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    searchProducts(search, tagIds, offset, limit){
        var url = 'products/search?offset='+offset+'&limit='+limit
        if(search){ url+='&search='+search }
        if(tagIds && tagIds.length){ url+='&tags='+tagIds.join(',') }
        return this.httpClient.get(url)
        .then((response: any) => { return response })
        .catch(console.error);
    }

    checkAuthByAccountAndProduct(productId){
        return this.httpClient.get('unityauth/check-auth/'+productId)
        .then((response: any) => { return response })
        .catch(console.error);
    }
}
