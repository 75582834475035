import { Injectable } from '@angular/core';
import { HTTPClientService } from './httpclient.service';

@Injectable({providedIn: 'root'})
export class CustomProductsService {

  constructor(
      private httpClient: HTTPClientService
  ) { }

  getCustomProductById(productId) {
    return this.httpClient.get('custom-product/'+productId)
      .then((response: any) => { return response })
      .catch(console.error);
  }


}
