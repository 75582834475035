var status:any = null

export const isBrowser = ()=>{
    if(typeof status['_value'] == 'undefined'){
        return status
    }
    else{
        return status['_value']
    }
}

export const setBrowser = (param)=>{
    status = param
}
